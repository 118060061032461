export const dummyData = [
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 90.37,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 50.16,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.756Z",
      updatedAt: "2023-05-26T11:51:07.756Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "use cases",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/use-cases",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.44,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 49.96,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.732Z",
      updatedAt: "2023-05-26T11:51:07.732Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "press release",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/press-release/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.25,
          width: 245,
          height: 141,
        },
      },
      hash: "artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
      ext: ".png",
      mime: "image/png",
      size: 66.53,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.714Z",
      updatedAt: "2023-05-26T11:51:07.714Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_1",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 77.36,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_1",
      ext: ".png",
      mime: "image/png",
      size: 42.13,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.645Z",
      updatedAt: "2023-05-26T11:51:07.645Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 90.37,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 50.16,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.756Z",
      updatedAt: "2023-05-26T11:51:07.756Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.44,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 49.96,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.732Z",
      updatedAt: "2023-05-26T11:51:07.732Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.25,
          width: 245,
          height: 141,
        },
      },
      hash: "artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
      ext: ".png",
      mime: "image/png",
      size: 66.53,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.714Z",
      updatedAt: "2023-05-26T11:51:07.714Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_1",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 77.36,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_1",
      ext: ".png",
      mime: "image/png",
      size: 42.13,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.645Z",
      updatedAt: "2023-05-26T11:51:07.645Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 90.37,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 50.16,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.756Z",
      updatedAt: "2023-05-26T11:51:07.756Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.44,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 49.96,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.732Z",
      updatedAt: "2023-05-26T11:51:07.732Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "/product-spotlights/test12",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.25,
          width: 245,
          height: 141,
        },
      },
      hash: "artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
      ext: ".png",
      mime: "image/png",
      size: 66.53,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.714Z",
      updatedAt: "2023-05-26T11:51:07.714Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "gentwo.com",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_1",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 77.36,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_1",
      ext: ".png",
      mime: "image/png",
      size: 42.13,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.645Z",
      updatedAt: "2023-05-26T11:51:07.645Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "gentwo.com",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 90.37,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 50.16,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.756Z",
      updatedAt: "2023-05-26T11:51:07.756Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "gentwo.com",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_2",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.44,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_2",
      ext: ".png",
      mime: "image/png",
      size: 49.96,
      url: "./uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_a5a3bf67e0.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.732Z",
      updatedAt: "2023-05-26T11:51:07.732Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "gentwo.com",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 89.25,
          width: 245,
          height: 141,
        },
      },
      hash: "artur_matosyan_4_YWU_Maftmag_unsplash_2_06e4030dac",
      ext: ".png",
      mime: "image/png",
      size: 66.53,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.714Z",
      updatedAt: "2023-05-26T11:51:07.714Z",
    },
    underline: true,
    isWrapLink: true,
  },
  {
    topic: "collectables",
    title: "Marcuard Heritage - Expanding the product range",
    authorInformation:
      "by <b class='underline underline-offset-4'>Julius Bär</b>, Switzerland",
    button: {
      url: "gentwo.com",
      text: "Read the link",
      variant: "ghost",
    },
    variant: "secondary",
    description:
      "GenTwo Digital enables clients to create and launch their portfolio on digital assets, establish track records and scale up their business while conquering digital markets.",
    picture: {
      name: "artur-matosyan-4YWUMaftmag-unsplash 2.png",
      alternativeText: null,
      caption: null,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
          hash: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash_1",
          mime: "image/png",
          name: "thumbnail_artur-matosyan-4YWUMaftmag-unsplash 2.png",
          path: null,
          size: 77.36,
          width: 245,
          height: 141,
        },
      },
      hash: "artur-matosyan-4YWUMaftmag-unsplash_1",
      ext: ".png",
      mime: "image/png",
      size: 42.13,
      url: "/uploads/artur_matosyan_4_YWU_Maftmag_unsplash_2_2b32978ab3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      createdAt: "2023-05-26T11:51:07.645Z",
      updatedAt: "2023-05-26T11:51:07.645Z",
    },
    underline: true,
    isWrapLink: true,
  },
];
