<template>
  <div
    class="flex w-full items-center justify-between"
    :class="props.customClasses"
  >
    <div v-for="item in iconList" :key="item.icon" :class="customClasses">
      <ShareIcon v-bind="item" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { iconType } from "~/components/ShareList/types";
import ShareIcon from "~/components/ShareList/ShareIcon.vue";

const props = defineProps({
  iconList: {
    type: Array as PropType<iconType[]>,
    required: true,
  },
  customClasses: {
    type: String,
    required: false,
    default: undefined,
  },
});
</script>
