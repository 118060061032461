<template>
  <div class="flex w-full flex-col gap-2 pt-8 capitalize">
    <StrapiButton
      v-for="(button, index) in buttons"
      :key="index"
      v-bind="button"
      class="w-full cursor-pointer justify-center"
    />
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
};
</script>
