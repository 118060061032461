<template>
  <span
    v-if="isIconMaterial"
    :class="['material-symbols-outlined', props.class && props.class]"
    :style="{
      color: iconColor,
      fontSize: `${getIconSize}px`,
    }"
  >
    {{ name }}
  </span>
  <Icon v-else v-bind="props" />
</template>

<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import { MaterialIconName, IconDimension } from "./types";
import { dimensionsMap, materialIconNames } from "./const";

const props = defineProps({
  name: {
    type: String as PropType<MaterialIconName>,
    required: true,
  },
  size: {
    type: String as PropType<IconDimension>,
    default: "base",
  },
  iconColor: {
    type: String,
    default: undefined,
  },
  class: {
    type: String,
    default: undefined,
  },
});

const getIconSize = vComputed(() => dimensionsMap[props.size]);
const isIconMaterial = vComputed(() => materialIconNames.includes(props.name));
</script>
