import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S47nQHVSFU from "/usr/src/app/node_modules/@nathanchase/nuxt-dayjs-module/dist/runtime/plugin.mjs";
import plugin_OrkQhMqHci from "/usr/src/app/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import strapi_plugin_35gTOGMBRa from "/usr/src/app/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import router_WMnTGednOQ from "/usr/src/app/plugins/router.ts";
import scroll_S7pZiC3d46 from "/usr/src/app/plugins/scroll.ts";
import sentry_3AyO8nEfhE from "/usr/src/app/plugins/sentry.ts";
import splidejs_vue_splide_yuDolQHrfj from "/usr/src/app/plugins/splidejs-vue-splide.ts";
import vue_dompurify_html_ER8SW1XOdk from "/usr/src/app/plugins/vue-dompurify-html.ts";
import vue_final_modal_pML93k5qcp from "/usr/src/app/plugins/vue-final-modal.ts";
import vue_gtm_client_stBjLl0OeM from "/usr/src/app/plugins/vue-gtm.client.ts";
import vue_scrollto_lZXr206Htt from "/usr/src/app/plugins/vue-scrollto.ts";
import vue_vimeo_player_S4iSUph2V4 from "/usr/src/app/plugins/vue-vimeo-player.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_S47nQHVSFU,
  plugin_OrkQhMqHci,
  strapi_plugin_35gTOGMBRa,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  router_WMnTGednOQ,
  scroll_S7pZiC3d46,
  sentry_3AyO8nEfhE,
  splidejs_vue_splide_yuDolQHrfj,
  vue_dompurify_html_ER8SW1XOdk,
  vue_final_modal_pML93k5qcp,
  vue_gtm_client_stBjLl0OeM,
  vue_scrollto_lZXr206Htt,
  vue_vimeo_player_S4iSUph2V4
]