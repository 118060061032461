<template>
  <a :href="getShareUrl" :target="linkTarget" rel="noopener">
    <MaterialIcon :name="icon" :size="iconSize" />
  </a>
</template>
<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import { iconVariants, navigateVariants } from "~/components/ShareList/types";
import MaterialIcon from "~/components/Icon/MaterialIcon.vue";
import { IconDimension } from "~/components/Icon/types";
import { sharedLinks } from "~/components/ShareList/const";

const props = defineProps({
  icon: {
    type: String as PropType<iconVariants>,
    required: true,
  },
  iconSize: {
    type: String as PropType<IconDimension>,
    default: "large",
  },
  variant: {
    type: String as PropType<navigateVariants>,
    required: true,
  },
  linkTo: {
    type: String,
    required: true,
  },
  linkTarget: {
    type: String,
    default: "_blank",
  },
});

const getShareUrl = vComputed(() => {
  if (props.variant === "share") {
    return sharedLinks[props.icon] + props.linkTo;
  } else {
    return props.linkTo;
  }
});
</script>
