<template>
  <svg
    width="251"
    height="250"
    viewBox="0 0 251 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5404_86142)">
      <path d="M238.5 62.5664H26.377V202H238.5V62.5664Z" fill="#25292B" />
      <path
        d="M127.464 69.3496H32.4121V195.217H127.464V69.3496Z"
        fill="#101114"
      />
      <path
        d="M179.832 69.3496H130.48V109.273H179.832V69.3496Z"
        fill="#434647"
      />
      <path
        d="M232.322 69.3496H182.971V109.273H232.322V69.3496Z"
        fill="#434647"
      />
      <path
        d="M179.832 112.318H130.48V152.241H179.832V112.318Z"
        fill="#434647"
      />
      <path
        d="M232.322 112.318H182.971V152.241H232.322V112.318Z"
        fill="#434647"
      />
      <path
        d="M179.832 155.293H130.48V195.217H179.832V155.293Z"
        fill="#434647"
      />
      <path
        d="M232.322 155.293H182.971V195.217H232.322V155.293Z"
        fill="#434647"
      />
      <path
        d="M79.9375 143.212C91.1865 143.212 100.306 134.101 100.306 122.862C100.306 111.623 91.1865 102.512 79.9375 102.512C68.6885 102.512 59.5693 111.623 59.5693 122.862C59.5693 134.101 68.6885 143.212 79.9375 143.212Z"
        fill="#434647"
      />
      <path
        d="M79.9375 102.512C91.1853 102.512 100.306 111.624 100.306 122.862C100.306 134.1 91.1853 143.212 79.9375 143.212"
        fill="#F346F7"
      />
      <path
        d="M76.2332 156.778H83.6412C99.4982 156.778 112.375 169.644 112.375 185.487V195.217H47.499V185.487C47.499 169.644 60.3762 156.778 76.2332 156.778Z"
        fill="#434647"
      />
      <path
        d="M112.376 185.487V195.217H79.9375V156.778H83.6415C99.5136 156.778 112.376 169.629 112.376 185.487Z"
        fill="#F346F7"
      />
      <path
        d="M155.375 179.148C158.85 179.148 161.667 176.334 161.667 172.862C161.667 169.39 158.85 166.576 155.375 166.576C151.901 166.576 149.084 169.39 149.084 172.862C149.084 176.334 151.901 179.148 155.375 179.148Z"
        fill="#25292B"
      />
      <path
        d="M155.375 166.576C158.853 166.576 161.666 169.387 161.666 172.862C161.666 176.337 158.853 179.148 155.375 179.148"
        fill="#FFFA1E"
      />
      <path
        d="M154.229 183.338H156.514C161.41 183.338 165.393 187.31 165.393 192.209V195.217H145.35V192.209C145.35 187.318 149.325 183.338 154.229 183.338Z"
        fill="#25292B"
      />
      <path
        d="M165.393 192.209V195.217H155.375V183.338H156.522C161.425 183.338 165.401 187.31 165.401 192.209H165.393Z"
        fill="#FFFA1E"
      />
      <path
        d="M207.427 179.148C210.902 179.148 213.719 176.334 213.719 172.862C213.719 169.39 210.902 166.576 207.427 166.576C203.953 166.576 201.136 169.39 201.136 172.862C201.136 176.334 203.953 179.148 207.427 179.148Z"
        fill="#25292B"
      />
      <path
        d="M207.427 166.576C210.904 166.576 213.718 169.387 213.718 172.862C213.718 176.337 210.904 179.148 207.427 179.148"
        fill="#F346F7"
      />
      <path
        d="M206.28 183.338H208.566C213.462 183.338 217.445 187.31 217.445 192.209V195.217H197.401V192.209C197.401 187.318 201.377 183.338 206.28 183.338Z"
        fill="#25292B"
      />
      <path
        d="M217.445 192.209V195.217H207.427V183.338H208.573C213.477 183.338 217.452 187.31 217.452 192.209H217.445Z"
        fill="#F346F7"
      />
      <path
        d="M207.427 136.187C210.902 136.187 213.719 133.373 213.719 129.902C213.719 126.43 210.902 123.616 207.427 123.616C203.953 123.616 201.136 126.43 201.136 129.902C201.136 133.373 203.953 136.187 207.427 136.187Z"
        fill="#25292B"
      />
      <path
        d="M207.427 123.616C210.904 123.616 213.718 126.427 213.718 129.902C213.718 133.376 210.904 136.187 207.427 136.187"
        fill="#C0C2C3"
      />
      <path
        d="M206.28 140.378H208.566C213.462 140.378 217.445 144.35 217.445 149.249V152.256H197.401V149.249C197.401 144.357 201.377 140.378 206.28 140.378Z"
        fill="#25292B"
      />
      <path
        d="M217.445 149.249V152.256H207.427V140.378H208.573C213.477 140.378 217.452 144.35 217.452 149.249H217.445Z"
        fill="#C0C2C3"
      />
      <path
        d="M155.375 136.187C158.85 136.187 161.667 133.373 161.667 129.902C161.667 126.43 158.85 123.616 155.375 123.616C151.901 123.616 149.084 126.43 149.084 129.902C149.084 133.373 151.901 136.187 155.375 136.187Z"
        fill="#25292B"
      />
      <path
        d="M155.375 123.616C158.853 123.616 161.666 126.427 161.666 129.902C161.666 133.376 158.853 136.187 155.375 136.187"
        fill="#FFA400"
      />
      <path
        d="M154.229 140.378H156.514C161.41 140.378 165.393 144.35 165.393 149.249V152.256H145.35V149.249C145.35 144.357 149.325 140.378 154.229 140.378Z"
        fill="#25292B"
      />
      <path
        d="M165.393 149.249V152.256H155.375V140.378H156.522C161.425 140.378 165.401 144.35 165.401 149.249H165.393Z"
        fill="#FFA400"
      />
      <path
        d="M155.375 93.2269C158.85 93.2269 161.667 90.4126 161.667 86.9411C161.667 83.4695 158.85 80.6553 155.375 80.6553C151.901 80.6553 149.084 83.4695 149.084 86.9411C149.084 90.4126 151.901 93.2269 155.375 93.2269Z"
        fill="#25292B"
      />
      <path
        d="M155.375 80.6553C158.853 80.6553 161.666 83.4666 161.666 86.9411C161.666 90.4156 158.853 93.2269 155.375 93.2269"
        fill="#C0C2C3"
      />
      <path
        d="M154.229 97.4175H156.514C161.41 97.4175 165.393 101.389 165.393 106.288V109.296H145.35V106.288C145.35 101.397 149.325 97.4175 154.229 97.4175Z"
        fill="#25292B"
      />
      <path
        d="M165.393 106.288V109.296H155.375V97.4175H156.522C161.425 97.4175 165.401 101.389 165.401 106.288H165.393Z"
        fill="#C0C2C3"
      />
      <path
        d="M207.427 93.2269C210.902 93.2269 213.719 90.4126 213.719 86.9411C213.719 83.4695 210.902 80.6553 207.427 80.6553C203.953 80.6553 201.136 83.4695 201.136 86.9411C201.136 90.4126 203.953 93.2269 207.427 93.2269Z"
        fill="#25292B"
      />
      <path
        d="M207.427 80.6553C210.904 80.6553 213.718 83.4666 213.718 86.9411C213.718 90.4156 210.904 93.2269 207.427 93.2269"
        fill="#FFA400"
      />
      <path
        d="M206.28 97.4175H208.566C213.462 97.4175 217.445 101.389 217.445 106.288V109.296H197.401V106.288C197.401 101.397 201.377 97.4175 206.28 97.4175Z"
        fill="#25292B"
      />
      <path
        d="M217.445 106.288V109.296H207.427V97.4175H208.573C213.477 97.4175 217.452 101.389 217.452 106.288H217.445Z"
        fill="#FFA400"
      />
      <path d="M62.587 49H4.5V89.6995H62.587V49Z" fill="#F346F7" />
      <path
        d="M33.5439 82.7427L55.798 100.357V85.9308L33.5439 82.7427Z"
        fill="#F346F7"
      />
      <path
        d="M25.6229 73.872C28.1227 73.872 30.1492 71.8473 30.1492 69.3498C30.1492 66.8523 28.1227 64.8276 25.6229 64.8276C23.1232 64.8276 21.0967 66.8523 21.0967 69.3498C21.0967 71.8473 23.1232 73.872 25.6229 73.872Z"
        fill="#101114"
      />
      <path
        d="M39.2011 73.872C41.7008 73.872 43.7273 71.8473 43.7273 69.3498C43.7273 66.8523 41.7008 64.8276 39.2011 64.8276C36.7013 64.8276 34.6748 66.8523 34.6748 69.3498C34.6748 71.8473 36.7013 73.872 39.2011 73.872Z"
        fill="#101114"
      />
    </g>
    <defs>
      <clipPath id="clip0_5404_86142">
        <rect
          width="234"
          height="153"
          fill="white"
          transform="translate(4.5 49)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
