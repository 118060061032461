import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import PostCard from "~/components/PostCard/PostCard.vue";

test("Post Card snapshot UI testing", () => {
  const wrapper = mount(PostCard);
  expect(wrapper.text()).toMatchSnapshot();
});

test("Post Card renders correctly with default props", async () => {
  expect(PostCard).toBeTruthy();

  const wrapper = mount(PostCard);

  expect(wrapper.props()).toEqual({
    topics: [],
    title: undefined,
    authorInformation: "",
    picture: undefined,
    variant: "secondary",
    description: "",
    underline: false,
    showButton: false,
    button: undefined,
  });

  await wrapper.setProps({ topics: [{ text: "News" }] });
  expect(wrapper.html()).toContain("News");

  await wrapper.setProps({
    title: "Blockchain business models made investable",
  });
  expect(wrapper.html()).toContain(
    "Blockchain business models made investable"
  );

  await wrapper.setProps({ picture: { url: "https://gentwo.com" } });
  expect(wrapper.html()).toContain("https://gentwo.com");

  await wrapper.setProps({ underline: true });
  expect(wrapper.props("underline")).toBe(true);

  await wrapper.setProps({ authorInformation: "By John Doe" });
  expect(wrapper.find(".authorInformation")).toBeTruthy();

  await wrapper.setProps({ variant: "primary" });
  expect(wrapper.classes()).toEqual(
    expect.arrayContaining([
      "bg-black-600",
      "hover:bg-black-500",
      "flex",
      "w-full",
      "text-white",
      "flex-col",
      "sm:flex-row-reverse",
      "sm:justify-end",
    ])
  );

  const section = wrapper.findAll("div")[1];
  await wrapper.setProps({ description: "This is a description" });
  expect(section.classes("p-5")).toBe(true);
});

test("Post Card wrap link to render successfully", async () => {
  expect(PostCard).toBeTruthy();

  const wrapper = mount(PostCard, {
    props: {
      showButton: true,
      button: {
        url: "https://gentwo.com",
        newTab: false,
        text: "read this article",
        variant: "ghost",
      },
    },
  });

  const link = wrapper.find("a");
  expect(link.attributes("target")).toBe("_self");
  await link.trigger("click");
});
