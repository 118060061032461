<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 301 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M60.4641 120.281C27.336 120.281 0.481934 147.122 0.481934 180.235C0.481934 213.347 27.336 240.188 60.4641 240.188H120.446V224.145C84.589 203.413 60.4641 164.665 60.4641 120.281Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M180.428 0.373901H60.4641V120.281C93.5923 120.281 120.446 147.122 120.446 180.235V224.145C138.093 234.349 158.577 240.188 180.428 240.188C246.685 240.188 300.393 186.506 300.393 120.281C300.393 54.0564 246.685 0.373901 180.428 0.373901Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M60.4641 120.281C60.4641 164.665 84.5889 203.413 120.446 224.145V180.235C120.446 147.122 93.5923 120.281 60.4641 120.281Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
