import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Chip from "../Chip.vue";
test("snapshot UI testing", () => {
  const wrapper = mount(Chip, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Chip component renders correctly with default props", async () => {
  expect(Chip).toBeTruthy();

  const wrapper = mount(Chip);

  expect(wrapper.props()).toEqual({
    text: "",
    icon: "",
    isActive: false,
    chipStyle: "default",
  });

  await wrapper.setProps({ text: "Chip Label Changed" });
  expect(wrapper.html()).toContain("Chip Label Changed");

  await wrapper.setProps({ icon: "Asterisk" });
  expect(wrapper.props("icon")).toBe("Asterisk");

  await wrapper.setProps({ isActive: true });
  expect(wrapper.props("isActive")).toBe(true);
});

test("Chip click event to render correctly", async () => {
  const wrapper = mount(Chip);

  const button = wrapper.find("button");
  await button.trigger("click");
});
