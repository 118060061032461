<template>
  <div
    class="grid grid-cols-1 gap-y-[10px]"
    :class="[baseClass, customClass]"
    :comp-id="baseClass"
  >
    <div v-if="headline?.title || headline?.subtitle" class="mb-6 lg:mb-9">
      <HeadlineGroup
        v-if="headline?.title || headline?.subtitle"
        v-bind="headline"
      />
    </div>
    <div class="flex flex-col gap-4">
      <div v-for="(link, index) in wrapLinks" :key="index">
        <Wraplink
          v-if="link.headline || link.headlineHighlighted || link.subTitle"
          v-bind="link"
        />
      </div>
    </div>
    <StrapiButton
      v-if="showMore?.text"
      v-bind="showMore"
      variant="ghost"
      icon="arrow_forward"
      underline
      class="lg:ml-auto"
      padding="p-0 pt-8"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactive as vReactive } from "vue";
import { wraperListInterface } from "~/components/Wraplink/types";
import StrapiButton from "~/components/StrapiButton/StrapiButton.vue";

const baseClass = "gt-wraplink-list";
type showMores = {
  text: string;
  url: string;
};

const props = defineProps({
  headline: {
    type: Object,
    default: () => ({}),
  },
  showMore: {
    type: Object as PropType<showMores>,
    default: () => ({}),
  },
  wrapLinks: {
    type: Array as PropType<wraperListInterface[]>,
    default: () => [],
  },
  customClass: {
    type: String,
    default: "",
  },
  buttonCustomStyle: {
    type: String,
    default: undefined,
  },
});

const modifyWraplinksData = vReactive<{ wrapLinks: wraperListInterface[] }>({
  wrapLinks: [],
});

if (props.buttonCustomStyle) {
  props.wrapLinks.forEach((link) => {
    link.buttonCustomStyle = props.buttonCustomStyle;
    modifyWraplinksData.wrapLinks.push(link);
  });
}
</script>
