import { LinkInfo, FooterItems } from "./types";

export const links: FooterItems[] = [
  {
    title: "Inside",
    items: [
      { text: "About Us", url: "/about" },
      { text: "Legal Disclaimer", url: "/" },
      { text: "Contact", url: "/company/contact-and-location" },
      { text: "Use Cases", url: "/use-cases" },
      { text: "Product Spotlights", url: "/product-shelf" },
    ],
  },
  {
    title: "Help",
    items: [
      { text: "FAQ", url: "/faq" },
      { text: "Lorem Ipsum", url: "https://www.gentwo.com/pulse/" },
      { text: "Lorem Ipsum", url: "https://www.google.com/" },
      { text: "Lorem Ipsum", url: "https://www.gentwo.com/pulse/" },
    ],
  },
  {
    title: "Topic",
    items: [
      { text: "Lorem Ipsum", url: "#" },
      { text: "Lorem Ipsum", url: "#" },
    ],
  },
  {
    title: "Lorem",
    items: [
      { text: "Lorem Ipsum", url: "#" },
      { text: "Lorem Ipsum", url: "#" },
    ],
  },

  {
    title: "Locations",
    items: [
      { text: "Zürich, Switzerland" },
      { text: "Pristina, Kosovo" },
      { text: "London, United Kingdom" },
      { text: "New York, United States of America" },
    ],
  },
];

export const terms: LinkInfo[] = [
  { text: "Privacy & Policy", url: "/privacy-policy" },
  { text: "Legal Disclaimer", url: "/legal-disclaimer" },
  { text: "Impressum", url: "/impressum" },
];

// add for testing purposes
export const links1: FooterItems[] = [
  {
    title: "Other",
    items: [
      { text: "Test1", url: "#1" },
      { text: "Test2", url: "#2" },
    ],
  },
  {
    title: "Lorem1",
    items: [
      { text: "Faq1", url: "#faq" },
      { text: "Faq1", url: "#12" },
    ],
  },
  {
    title: "Test",
    items: [
      { text: "Test1", url: "#" },
      { text: "Test2", url: "#" },
    ],
  },
  {
    title: "Location",
    items: [{ text: "Zürich, Switzerland" }, { text: "Prishtina, Kosovo" }],
  },
];

export const terms1: LinkInfo[] = [
  { text: "Test Term", url: "#1" },
  { text: "Test Term2", url: "#2" },
  { text: "Test Term3", url: "#3" },
];
