<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 653 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1421_76720)">
      <g>
        <path
          d="M377.274 124.452C384.094 143.809 387.808 164.619 387.808 186.299V372.548H201.47C179.78 372.548 158.96 368.835 139.593 362.018C139.444 365.507 139.357 369.021 139.357 372.548C139.357 509.701 250.589 620.879 387.808 620.879C525.027 620.879 636.258 509.701 636.258 372.548V124.216H387.808C384.28 124.216 380.764 124.303 377.274 124.452Z"
          fill="#FFFA1E"
        />
      </g>
      <g>
        <path
          d="M377.274 124.452C351.758 51.9884 282.689 0.0498047 201.471 0.0498047C98.5625 0.0498047 15.1328 83.4396 15.1328 186.299C15.1328 267.478 67.0962 336.514 139.594 362.018C144.973 233.246 248.44 129.828 377.274 124.452Z"
          fill="#F346F7"
        />
      </g>
      <g>
        <path
          d="M387.81 372.548V186.299C387.81 164.62 384.096 143.81 377.276 124.452C248.442 129.829 144.975 233.246 139.596 362.019C158.962 368.835 179.783 372.548 201.472 372.548H387.81Z"
          fill="#FFA400"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1421_76720">
        <rect
          width="652.979"
          height="636.5"
          fill="white"
          transform="translate(0 0.295898)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
