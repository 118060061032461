<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 301 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M240.323 120.319C273.452 120.319 300.306 93.4774 300.306 60.365C300.306 27.2526 273.452 0.411332 240.323 0.411332H180.341V16.4549C216.199 37.1869 240.323 75.9349 240.323 120.319Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M120.359 240.226L240.323 240.226L240.323 120.319C207.195 120.319 180.341 93.4773 180.341 60.365V16.4549C162.695 6.25081 142.211 0.411317 120.359 0.411317C54.1028 0.411317 0.39474 54.0938 0.39474 120.319C0.39474 186.543 54.1028 240.226 120.359 240.226Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M240.323 120.319C240.323 75.9349 216.199 37.1869 180.341 16.4549V60.365C180.341 93.4774 207.195 120.319 240.323 120.319Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
