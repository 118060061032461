export const dummyVideo = {
  id: 1,
  title: "Swiss asset manager Clarus Capital on GenTwo",
  videoUrl:
    "https://player.vimeo.com/video/217514831?background=1&quality=1080p",
  // "ea3hqz5q8r",
  overlay: true,
  button: {},
  playButtonText: {},
  description: "Roger Ganz, Head Asset Management, Clarus Capital",
  poster: {
    data: {
      attributes: {
        ext: "",
        url: "https://plus.unsplash.com/premium_photo-1684783848349-fe1d51ab831b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
        hash: "",
        mime: "",
        name: "",
        size: 0,
        width: "",
        height: "",
        path: "",
      },
    },
  },
};
const differentPosters = [
  {
    url: "https://images.unsplash.com/photo-1685399124857-ab2bc1053311?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1684783848349-fe1d51ab831b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1682687981974-c5ef2111640c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1742&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1685399124857-ab2bc1053311?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80",
  },
  {
    url: "https://plus.unsplash.com/premium_photo-1684783848349-fe1d51ab831b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  },
];
export const videoList = [0, 1, 2, 3, 4].map((id) => ({
  ...dummyVideo,
  id,
  poster: {
    ...dummyVideo.poster,
    url: differentPosters[id].url,
  },
}));
