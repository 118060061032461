import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import AccordionList from "~/components/Accordion/AccordionList.vue";

test("AccordionList Snapshot UI testing", () => {
  const wrapper = mount(AccordionList);
  expect(wrapper.html()).toMatchSnapshot();
});

test("AccordionList renders correctly with default props", async () => {
  expect(AccordionList).toBeTruthy(AccordionList);

  const wrapper = mount(AccordionList);

  expect(wrapper.props()).toEqual({
    headline: undefined,
    accordions: undefined,
  });

  await wrapper.setProps({ title: "Title Changed" });
  expect(wrapper.html()).toContain("Title Changed");

  await wrapper.setProps({ accordions: [{ title: "Text Changed Item" }] });
  expect(wrapper.html()).toContain("Text Changed Item");
});

test("AccordionList expanded", () => {
  expect(AccordionList).toBeTruthy(AccordionList);

  const wrapper = mount(AccordionList, {
    props: { accordions: [{ title: "test", description: "test", open: true }] },
  });

  expect(wrapper.find("details").attributes("open")).toBe("true");
});
