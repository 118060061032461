export const iconAlign = ["left", "right"] as const;
export const iconVariants = [
  "LeafV1Default",
  "LeafV2Default",
  "LeafV2Large",
  "LeafV3Default",
  "LeafV4Default",
  "LeafV4Large",
  "LeafV5Default",
  "LeafV6Default",
  "LeafV6Small",
  "LeafV6Large",
] as const;
