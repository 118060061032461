<template>
  <svg
    width="528"
    height="423"
    viewBox="0 0 528 423"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M189.795 380.488C138.663 341.89 105.6 280.565 105.6 211.5C105.6 211.299 105.6 211.098 105.6 210.908C47.2771 210.908 0 258.252 0 316.658V422.408H105.6C139.973 422.408 170.512 405.953 189.795 380.488Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M211.2 316.658C211.2 340.642 203.227 362.754 189.795 380.489C225.139 407.169 269.122 423 316.8 423C433.446 423 528 328.311 528 211.5V3.05176e-05H316.8C200.355 3.05176e-05 105.927 94.3713 105.611 210.908C163.933 210.908 211.2 258.252 211.2 316.658Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M189.795 380.488C203.227 362.744 211.2 340.631 211.2 316.658C211.2 258.252 163.923 210.908 105.611 210.908C105.611 211.109 105.611 211.31 105.611 211.5C105.611 280.565 138.674 341.89 189.806 380.488H189.795Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
