import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Cta from "../Cta.vue";

test("CTA Snapshot UI testing", () => {
  const wrapper = mount(Cta, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Cta renders correctly with default props", async () => {
  expect(Cta).toBeTruthy(Cta);

  const wrapper = mount(Cta);

  expect(wrapper.props()).toEqual({
    iconAlign: "left",
    iconVariant: undefined,
    description: undefined,
    button: undefined,
    class: undefined,
    iconClass: undefined,
  });

  await wrapper.setProps({ iconAlign: "right" });
  expect(wrapper.props("iconAlign")).toBe("right");
  await wrapper.setProps({ iconVariant: "LeafV1Default" });
  expect(wrapper.props("iconVariant")).toBe("LeafV1Default");
  await wrapper.setProps({ description: "Description Changed" });
  expect(wrapper.html()).toContain("Description Changed");
  await wrapper.setProps({
    button:
      "{variant: 'primary', text: 'Contact Sales team', url: 'https://gentwo.com', newTab: false}",
  });
  expect(wrapper.props("button")).toBe(
    "{variant: 'primary', text: 'Contact Sales team', url: 'https://gentwo.com', newTab: false}"
  );
});
