import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import ShareList from "~/components/ShareList/ShareList.vue";

test("snapshot UI testing", () => {
  const wrapper = mount(ShareList, {
    props: {
      iconList: [],
    },
  });
  expect(wrapper.text()).toMatchSnapshot();
});

test("ShareList work with share variant", async () => {
  const wrapper = await mount(ShareList, {
    props: {
      iconList: [
        {
          icon: "Facebook",
          variant: "share",
          linkTo: "https://gentwo.com",
        },
        {
          icon: "Twitter",
          variant: "share",
          linkTo: "https://gentwo.com",
        },
        {
          icon: "Linkedin",
          variant: "share",
          linkTo: "https://gentwo.com",
        },
      ],
    },
  });
  expect(wrapper.find("a").exists()).toBe(true);
  expect(wrapper.find("a").attributes("href")).toBe(
    "https://www.facebook.com/sharer/sharer.php?u=https://gentwo.com"
  );
});

test("ShareList work with redirect variant", async () => {
  const wrapper = await mount(ShareList, {
    props: {
      iconList: [
        {
          icon: "Facebook",
          variant: "redirect",
          linkTo: "/contact-page",
        },
        {
          icon: "Twitter",
          variant: "redirect",
          linkTo: "/contact-page",
        },
        {
          icon: "Linkedin",
          variant: "redirect",
          linkTo: "/contact-page",
        },
      ],
    },
  });
  expect(wrapper.find("a").exists()).toBe(true);
  expect(wrapper.find("a").attributes("href")).toBe("/contact-page");
});
