<template>
  <svg
    width="403"
    height="611"
    viewBox="0 0 403 611"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M528.615 -113.788C538.569 -85.5354 543.99 -55.162 543.99 -23.52V248.318H272.036C240.381 248.318 209.994 242.9 181.729 232.95C181.512 238.043 181.385 243.172 181.385 248.318C181.385 448.5 343.723 610.77 543.99 610.77C744.256 610.77 906.594 448.5 906.594 248.318V-114.133H543.99C538.841 -114.133 533.71 -114.006 528.615 -113.788Z"
      fill="#FFFA1E"
    />
    <path
      d="M528.615 -113.788C491.375 -219.552 390.571 -295.358 272.036 -295.358C121.845 -295.358 0.0820312 -173.647 0.0820312 -23.52C0.0820312 94.9652 75.9209 195.727 181.729 232.95C189.579 45.0013 340.586 -105.941 528.615 -113.788Z"
      fill="#F346F7"
    />
    <path
      d="M543.991 248.318V-23.5201C543.991 -55.1621 538.57 -85.5355 528.616 -113.789C340.588 -105.942 189.581 45.0013 181.73 232.95C209.996 242.9 240.382 248.318 272.037 248.318H543.991Z"
      fill="#FFA400"
    />
  </svg>
</template>
