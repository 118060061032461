<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.72418 11.334H12.8509C14.1175 11.334 14.7842 10.6673 14.7842 9.40066V1.33398H2.78418V9.40066C2.79085 10.6673 3.45752 11.334 4.72418 11.334Z"
      stroke="#FFFA1E"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.12451 1.33398H15.4578"
      stroke="url(#paint0_linear_8850_5322)"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.12451 14.6673L8.79118 13.334V11.334"
      stroke="url(#paint1_linear_8850_5322)"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.4577 14.6673L8.79102 13.334"
      stroke="url(#paint2_linear_8850_5322)"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.79102 7.33398L7.89101 5.58065C8.05768 5.44065 8.27768 5.48065 8.39101 5.66732L9.19102 7.00065C9.30435 7.18732 9.52435 7.22065 9.69102 7.08732L11.791 5.33398"
      stroke="url(#paint3_linear_8850_5322)"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8850_5322"
        x1="2.1071"
        y1="2.33398"
        x2="15.4436"
        y2="2.33398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFA1E" />
        <stop offset="0.494792" stop-color="#FFA400" />
        <stop offset="1" stop-color="#F346F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8850_5322"
        x1="6.12103"
        y1="14.6673"
        x2="8.78832"
        y2="14.6673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFA1E" />
        <stop offset="0.494792" stop-color="#FFA400" />
        <stop offset="1" stop-color="#F346F7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8850_5322"
        x1="8.78753"
        y1="14.6673"
        x2="11.4548"
        y2="14.6673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFA1E" />
        <stop offset="0.494792" stop-color="#FFA400" />
        <stop offset="1" stop-color="#F346F7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8850_5322"
        x1="5.78318"
        y1="7.33398"
        x2="11.7846"
        y2="7.33398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFA1E" />
        <stop offset="0.494792" stop-color="#FFA400" />
        <stop offset="1" stop-color="#F346F7" />
      </linearGradient>
    </defs>
  </svg>
</template>
