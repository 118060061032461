export const dummyData = [
  {
    authorName: "Patrick Michaelss",
    title: "Hello",
    authorPosition: "President Zuri-Invest.ch",
    testimonial:
      "These equities have very good margins, while metal prices have been going higher. I don't see that reflected in the whole bandwidth of small, mid and large caps.",
    authorImage: {
      url: "https://capecoraltech.edu/wp-content/uploads/2016/01/tutor-8-3.jpg",
    },
  },
  {
    authorName: "Patrick Michaelss",
    title: "Hello",
    authorPosition: "President Zuri-Invest.ch",
    testimonial:
      "These equities have very good margins, while metal prices have been going higher. I don't see that reflected in the whole bandwidth of small, mid and large caps.",
    authorImage: {
      url: "https://capecoraltech.edu/wp-content/uploads/2016/01/tutor-8-3.jpg",
    },
  },
  {
    authorName: "Patrick Michaelss",
    title: "Hello",
    authorPosition: "President Zuri-Invest.ch",
    testimonial:
      "These equities have very good margins, while metal prices have been going higher. I don't see that reflected in the whole bandwidth of small, mid and large caps.",
    authorImage: {
      url: "https://capecoraltech.edu/wp-content/uploads/2016/01/tutor-8-3.jpg",
    },
  },
  {
    authorName: "Patrick Michaelss",
    title: "Hello",
    authorPosition: "President Zuri-Invest.ch",
    testimonial:
      "These equities have very good margins, while metal prices have been going higher. I don't see that reflected in the whole bandwidth of small, mid and large caps.",
    authorImage: {
      url: "https://capecoraltech.edu/wp-content/uploads/2016/01/tutor-8-3.jpg",
    },
  },
];
