import { test, expect } from "vitest";
import { mount } from "@vue/test-utils";
import GridLayout from "../GridLayout.vue";

test("snapshot Grid Layout UI testing", () => {
  const wrapper = mount(GridLayout, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Grid Layout renders correctly with fullBleed variant", () => {
  const wrapper = mount(GridLayout, {
    props: { variant: "fullBleed" },
  });
  expect(wrapper.classes()).toEqual(
    expect.arrayContaining([
      "gap-4",
      "grid-cols-4",
      "px-4",
      "xl:px-0",
      "grid",
      "w-full",
      "lg:grid-cols-12",
      "lg:gap-5",
      "grid-cols-6",
    ])
  );
});

test("Grid Layout renders correctly with guttersOnly variant", () => {
  const wrapper = mount(GridLayout, {
    props: { variant: "guttersOnly" },
  });

  expect(wrapper.classes()).toEqual(
    expect.arrayContaining([
      "grid-cols-1",
      "grid",
      "w-full",
      "gap-4",
      "lg:grid-cols-12",
      "lg:gap-5",
    ])
  );
});
