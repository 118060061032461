<template>
  <div
    class="bg-black-600 hover:bg-black-500 group flex h-full cursor-pointer flex-col text-white"
    @click.stop="showVideo(video)"
  >
    <div v-if="video" class="group relative">
      <Image
        v-if="video.poster"
        :url="video.poster.data?.attributes.url"
        class="aspect-square object-cover grayscale transition duration-300 ease-in-out group-hover:grayscale-0 sm:aspect-[5/3]"
        width="100%"
      />
      <div
        class="overlay bg-black-950/10 group absolute left-0 top-0 h-full w-full hover:cursor-pointer"
      >
        <div
          class="bg-mango absolute inset-0 m-auto flex h-12 w-12 items-center justify-center rounded-full"
        >
          <Icon
            name="PlayButton"
            class="flex h-11 w-11 items-center justify-center rounded-full bg-black transition-all duration-200 ease-in-out group-hover:scale-110"
            height="22"
            width="18"
            color="white"
          />
        </div>
      </div>
    </div>
    <div
      :class="[
        'bg-black-600 flex flex-col gap-2 p-5 text-white sm:px-8 sm:py-6',
        { 'h-full': isBaseSlider },
      ]"
    >
      <Headline
        v-if="video.title"
        level="h6"
        variant="xsmall"
        :text="video.title"
        headline-class="font-mierb font-semibold"
      />
      <p
        v-if="video.description"
        class="font-mierb text-bodyXLarge font-medium"
      >
        {{ video.description }}
      </p>
    </div>
    <BaseModal
      class="h-full w-full px-4 opacity-100 lg:px-0"
      content-class="h-4/5 w-full md:w-3/4 2xl:w-1/2"
      :model-value="showModal"
      @close-modal="showModal = false"
    >
      <Frames
        :variant="video.iframeType ?? 'vimeo'"
        :url="video.videoUrl"
        :video-key="video.id"
      />
    </BaseModal>
  </div>
</template>
<script lang="ts" setup>
import { ref as vRef, PropType } from "vue";
import { Video } from "./types";
import BaseModal from "~/components/Modal/BaseModal.vue";
import Image from "~/components/Image/Image.vue";
import { Actions } from "~/types/gtag";
import { trackEvent } from "~/utils/gtag";

const props = defineProps({
  video: { type: Object as PropType<Video>, default: () => {} },
  isBaseSlider: { type: Boolean, default: false },
});
const showModal = vRef<boolean>(false);
function showVideo(video: Video) {
  showModal.value = !showModal.value;
  trackEvent({
    name: Actions.WATCH_VIDEO,
    campaignAttributes: false,
    video,
  });
}
</script>
