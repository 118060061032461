import { menuItem } from "~/components/Header/types";

export const menuItems: menuItem[] = [
  {
    title: "Assets",
    isActive: false,
    items: [
      {
        title: "digital",
        subItems: [
          {
            title: "Digital Assets",
            newTab: false,
            path: "/assets/digital-assets",
          },
        ],
      },
      {
        title: "alternative",
        subItems: [
          {
            title: "Alternative Assets",
            newTab: false,
            path: "/assets/alternative-assets",
          },
        ],
      },
      {
        title: "traditional",
        subItems: [
          {
            title: "Traditional Assets",
            newTab: false,
            path: "/assets/traditional-assets",
          },
        ],
      },
    ],
    cta: {
      description:
        "There is much to discover about our company. If you have any questions, please feel free to contact us.",
      iconVariant: "LeafV2Default",
      button: {
        variant: "primary",
        text: "Contact Us",
        url: "https://gentwo.com",
        newTab: false,
      },
    },
  },
  {
    title: "Solutions",
    isActive: false,
    items: [
      {
        title: "who we serve",
        subItems: [{ title: "Solutions", newTab: false, path: "/solutions" }],
      },
      {
        title: "technology",
        subItems: [
          {
            title: "GenTwo Pro",
            newTab: true,
            path: "https://app.gentwo.com/login",
          },
        ],
      },
    ],
    cta: {
      description:
        "There is much to discover about our company. If you have any questions, please feel free to contact us.",
      iconVariant: "LeafV2Default",
      button: {
        variant: "primary",
        text: "Contact Us",
        url: "https://gentwo.com",
        newTab: false,
      },
    },
  },
  {
    title: "Insights",
    isActive: false,
    items: [
      {
        title: "our work",
        subItems: [
          { title: "Spotlights", newTab: false, path: "/insights/spotlights" },
          { title: "Use Cases", newTab: false, path: "/insights/use-cases" },
          {
            title: "Testimonials",
            newTab: false,
            path: "/insights/testimonial",
          },
        ],
      },
      {
        title: "resources",
        subItems: [
          { title: "Webinars", newTab: false, path: "/resources/webinars" },
          {
            title: "Newsletters",
            newTab: false,
            path: "/resources/newsletters",
          },
        ],
      },
    ],
    cta: {
      description:
        "There is much to discover about our company. If you have any questions, please feel free to contact us.",
      iconVariant: "LeafV2Default",
      button: {
        variant: "primary",
        text: "Contact Us",
        url: "https://gentwo.com",
        newTab: false,
      },
    },
  },
  {
    title: "Company",
    isActive: false,
    items: [
      {
        title: "about us",
        subItems: [
          { title: "About GenTwo", newTab: false, path: "/about" },

          { title: "Team", newTab: false, path: "/team" },
        ],
      },
      {
        title: "Working at gentwo",
        subItems: [
          {
            title: "Why Working With Us",
            newTab: false,
            path: "/company/work-with-us",
          },
        ],
      },
    ],
    cta: {
      description:
        "There is much to discover about our company. If you have any questions, please feel free to contact us.",
      iconVariant: "LeafV2Default",
      button: {
        variant: "primary",
        text: "Contact Us",
        url: "https://gentwo.com",
        newTab: false,
      },
    },
  },
  {
    title: "Network",
    isActive: false,
    path: "/network",
  },
  {
    title: "Contact",
    isActive: false,
    path: "/company/contact-and-location",
  },
];
