import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import StrapiCard from "~/components/StrapiCard/StrapiCard.vue";

test("snapshot UI testing", () => {
  const wrapper = mount(StrapiCard, {});
  expect(wrapper.text()).toMatchSnapshot();
});

// To execute the test for the teamCard variant, you need to navigate to the Image.vue component and comment out the line where the variable "url" is assigned using the "useStrapiUrl()" function.
// test("renders correctly with given variant 'teamCard'", () => {
//   const givenProps = {
//     variant: "teamCard",
//     targetLink: "_self",
//     cardLinkTo: "/feed",
//     imageUrl:
//       "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1878&q=80",
//     title: "Marc P. Bernegger",
//     titleVariant: "xxsmall",
//     description: "Second headline",
//     descriptionVariant: "bodyLarge",
//   };

//   const wrapper = mount(StrapiCard, {
//     propsData: givenProps,
//   });

//   expect(wrapper.exists()).toBe(true);

//   expect(wrapper.props().variant).toBe(givenProps.variant);
//   expect(wrapper.props().imageUrl).toEqual(givenProps.imageUrl);
//   expect(wrapper.props().title).toEqual(givenProps.title);
//   expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
//   expect(wrapper.props().targetLink).toEqual(givenProps.targetLink);
//   expect(wrapper.props().cardLinkTo).toEqual(givenProps.cardLinkTo);
//   expect(wrapper.props().description).toEqual(givenProps.description);
//   expect(wrapper.props().descriptionVariant).toEqual(
//     givenProps.descriptionVariant
//   );

//   const currentUrl = "http://localhost";
//   Object.defineProperty(window, "location", {
//     writable: true,
//     value: {
//       href: currentUrl,
//     },
//   });

//   const anchorTag = wrapper.find("a");
//   anchorTag.trigger("click");

//   Object.defineProperty(window, "location", {
//     writable: true,
//     value: {
//       href: givenProps.cardLinkTo,
//     },
//   });

//   expect(window.location.href).toBe(givenProps.cardLinkTo);
// });

test("renders correctly with given variant 'digitalInvestments'", () => {
  const givenProps = {
    variant: "digitalInvestments",
    cardLinkTo: undefined,
    iconName: "Bitcoin",
    iconSIze: "large",
    title: "Alternative Assets",
    titleVariant: "xsmall",
    description: "Second headline",
    descriptionVariant: "bodyLarge",
  };
  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().cardLinkTo).toEqual(givenProps.cardLinkTo);

  const currentUrl = "http://localhost";
  Object.defineProperty(window, "location", {
    writable: true,
    value: {
      href: currentUrl,
    },
  });

  // const anchorTag = wrapper.find("a");
  // anchorTag.trigger("click");

  Object.defineProperty(window, "location", {
    writable: true,
    value: {
      href: givenProps.cardLinkTo,
    },
  });

  expect(window.location.href).toBe(givenProps.cardLinkTo);
});

test("renders correctly with given variant 'digitalInvestmentsAssets'", () => {
  const givenProps = {
    variant: "digitalInvestmentsAssets",
    cardLinkTo: undefined,
    iconName: "Bitcoin",
    iconSIze: "large",
    title: "Alternative Assets",
    titleVariant: "xsmall",
    description:
      "Private Equity · Private Debt · Real Estate · Infrastructure · Collectables ",
    descriptionVariant: "bodyLarge",
  };
  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().cardLinkTo).toEqual(givenProps.cardLinkTo);

  const currentUrl = "http://localhost";
  Object.defineProperty(window, "location", {
    writable: true,
    value: {
      href: currentUrl,
    },
  });

  // const anchorTag = wrapper.find("a");
  // anchorTag.trigger("click");

  Object.defineProperty(window, "location", {
    writable: true,
    value: {
      href: givenProps.cardLinkTo,
    },
  });

  expect(window.location.href).toBe(givenProps.cardLinkTo);
});

test("renders correctly with given variant 'bankValute'", () => {
  const givenProps = {
    variant: "bankValute",
    iconName: "Bank",
    iconSize: "large",
    title: "Alternative Assets",
    titleVariant: "xsmall",
    description:
      "Private Equity · Private Debt · Real Estate · Infrastructure · Collectables ",
    descriptionVariant: "bodyLarge",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().iconName).toEqual(givenProps.iconName);
  expect(wrapper.props().iconSize).toEqual(givenProps.iconSize);
});

test("renders correctly with given variant 'stepsForInvestmentProduct'", () => {
  const givenProps = {
    variant: "stepsForInvestmentProduct",
    iconName: "Lamg",
    iconSize: "large",
    title: "Get in contact and tell us about your investment idea",
    titleVariant: "small",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    descriptionVariant: "xxsmall",
    subtitle: "Step 1",
    subtitleVariant: "bodyXLarge",
    descriptionNumber: 1,
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().iconName).toEqual(givenProps.iconName);
  expect(wrapper.props().iconSize).toEqual(givenProps.iconSize);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().subtitle).toEqual(givenProps.subtitle);
  expect(wrapper.props().description).toEqual(givenProps.description);
  expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
  expect(wrapper.props().subtitleVariant).toEqual(givenProps.subtitleVariant);
  expect(wrapper.props().descriptionNumber).toEqual(
    givenProps.descriptionNumber
  );
  expect(wrapper.props().descriptionVariant).toEqual(
    givenProps.descriptionVariant
  );
});

test("renders correctly with given variant 'reachPlus'", () => {
  const givenProps = {
    variant: "reachPlus",
    statisticsForCard: "230 +",
    title: "Platforms",
    titleVariant: "xsmall",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    descriptionVariant: "bodyLarge",
    subtitleVariant: "bodyXLarge",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().statisticsForCard).toBe(givenProps.statisticsForCard);
  expect(wrapper.props().title).toEqual(givenProps.title);
});

test("renders correctly with given variant 'plainCard'", () => {
  const givenProps = {
    variant: "plainCard",
    title: "Open platform: Free choice of counterparties",
    titleVariant: "xsmall",
    description: "Off-balance-sheet, your brand, your framework, your platform",
    descriptionVariant: "bodyLarge",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().description).toEqual(givenProps.description);
  expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
  expect(wrapper.props().descriptionVariant).toEqual(
    givenProps.descriptionVariant
  );
});

test("renders correctly with given variant 'ourCoreValues'", () => {
  const givenProps = {
    variant: "ourCoreValues",
    iconName: "xlarge",
    iconSize: "large",
    title: "Limitless",
    titleVariant: "small",
    description:
      "Pushing the boundaries of possibility, allows our creativity to flourish. We don’t see obstacles, only opportunities..",
    descriptionVariant: "xxsmall",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().iconName).toEqual(givenProps.iconName);
  expect(wrapper.props().iconSize).toEqual(givenProps.iconSize);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
  expect(wrapper.props().description).toEqual(givenProps.description);
  expect(wrapper.props().descriptionVariant).toEqual(
    givenProps.descriptionVariant
  );
});

test("renders correctly with given variant 'statisticsCard'", () => {
  const givenProps = {
    variant: "statisticsCard",
    statisticsForCard: "71",
    title: "Employess",
    titleVariant: "bodyLarge",
    iconName: "People",
    iconSize: "large",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().statisticsForCard).toBe(givenProps.statisticsForCard);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
  expect(wrapper.props().iconName).toEqual(givenProps.iconName);
  expect(wrapper.props().iconSize).toEqual(givenProps.iconSize);
});

test("renders correctly with given variant 'benefitsWorkingWithUs'", () => {
  const givenProps = {
    variant: "benefitsWorkingWithUs",
    title: "Health Insurance",
    titleVariant: "xxsmall",
    iconName: "HealthInsurance",
    iconSize: "xlarge",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().titleVariant).toEqual(givenProps.titleVariant);
  expect(wrapper.props().iconName).toEqual(givenProps.iconName);
  expect(wrapper.props().iconSize).toEqual(givenProps.iconSize);
});

test("renders correctly with given variant 'locationCard'", () => {
  const givenProps = {
    variant: "locationCard",
    title: "Pristina",
    titleVariant: "xsmall",
    frameLocation: "Brandschenkestrasse 45, 8002 Zürich, Switzerland",
    framePhone: "+41 44 512 75 00",
    frameEmail: "contact@gentwo.com",
  };

  const wrapper = mount(StrapiCard, {
    propsData: givenProps,
  });

  expect(wrapper.exists()).toBe(true);

  expect(wrapper.props().variant).toBe(givenProps.variant);
  expect(wrapper.props().title).toEqual(givenProps.title);
  expect(wrapper.props().frameLocation).toEqual(givenProps.frameLocation);
  expect(wrapper.props().framePhone).toEqual(givenProps.framePhone);
  expect(wrapper.props().frameEmail).toEqual(givenProps.frameEmail);
});
