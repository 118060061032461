interface Variants {
  [key: string]: string;
}

export const variants = ["primary", "primarySm", "linkTag", "default"] as const;

export const colors = [
  "orange",
  "yellow",
  "green",
  "blue",
  "black",
  "white",
] as const;

export const background = [
  "orange",
  "red",
  "pink",
  "yellow",
  "white",
  "blue",
  "mango",
  "transparent",
  "green",
] as const;

export const variantClasses = {
  primary:
    "text-black-600 bg-pink-500 py-2 px-4 uppercase tracking-wider font-semibold",
  primarySm:
    "text-black-600 bg-pink-500 px-2.5 py-1.5 uppercase tracking-wider font-semibold",
  linkTag: "bg-transparent uppercase tracking-wider font-semibold",
  default: "p-1 capitalize font-semibold",
} as Variants;

export const titleColorClasses = {
  yellow: "text-yellow-500",
  orange: "text-orange-500",
  green: "text-green-500",
  blue: "text-blue-600",
  black: "text-black",
  white: "text-white",
} as Variants;

export const backgroundClasses = {
  orange: "bg-orange-500",
  red: "bg-red-600",
  pink: "bg-pink-500",
  yellow: "bg-yellow-500",
  white: "bg-white",
  blue: "bg-blue-400",
  mango: "bg-mango",
  transparent: "bg-transparent",
  green: "bg-[#0DC761]",
} as Variants;
