<template>
  <section class="flex h-full items-center justify-center">
    <slot v-if="variant === 'map'">
      <div :class="[variantClass]">
        <iframe
          :src="url"
          width="100%"
          height="100%"
          loading="lazy"
          allow="fullscreen"
        ></iframe>
      </div>
    </slot>
    <slot v-else-if="variant === 'youtube'">
      <iframe
        :id="`yt-player-${url}`"
        width="100%"
        height="100%"
        :src="`https://www.youtube.com/embed/${url}?autoplay=1`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </slot>
    <slot v-else-if="variant === 'wistia'">
      <div
        :id="wistiaId"
        :class="[
          variantClass,
          'wistia_embed playlistLinks=auto',
          'wistia_async_' + url,
        ]"
      ></div>
    </slot>
    <slot v-else-if="variant === 'vimeo'">
      <vimeo-player
        ref="videoPlayerEl"
        :key="videoKey"
        :options="{ responsive: true }"
        :video-url="url"
        class="w-full"
      />
    </slot>
    <slot v-else>
      <Headline
        level="h3"
        variant="xlarge"
        text="Please add the variant prop to render"
      />
    </slot>
  </section>
</template>

<script setup lang="ts">
import {
  computed as vComputed,
  PropType,
  onMounted as vOnMounted,
  ref as vRef,
} from "vue";
import { variantClasses } from "./const";
import { variantType } from "./types";

const props = defineProps({
  variant: {
    type: String as PropType<variantType>,
    default: "",
  },
  url: {
    type: String,
    default: undefined,
  },
  videoKey: {
    type: String,
    default: null,
  },
});
const videoPlayerEl = vRef<HTMLVideoElement>();
const variantClass = vComputed<string>(() => variantClasses[props.variant]);
const scriptElement = vRef();
// Generate a unique ID for the Wistia container
const wistiaId = vComputed(
  () => `wistia-${Math.random().toString(36).substring(2, 9)}`
);

vOnMounted(() => {
  const wistiaScript = document.createElement("script");

  wistiaScript.setAttribute("async", "");
  wistiaScript.setAttribute("defer", "");

  wistiaScript.setAttribute(
    "src",
    `https://fast.wistia.com/assets/external/E-v1.js`
  );

  scriptElement.value = document.body.appendChild(wistiaScript);
});
</script>
