import { test, expect } from "vitest";
import { mount } from "@vue/test-utils";
import Frames from "../Frames.vue";

test("Frames Snapshot UI testing", () => {
  const wrapper = mount(Frames, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Frames renders correctly with default props", () => {
  expect(Frames).toBeTruthy();

  const wrapper = mount(Frames);

  expect(wrapper.props()).toEqual({
    variant: "",
    url: undefined,
    videoKey: null,
  });
  expect(wrapper.find("variant").exists()).toBe(false);
  expect(wrapper.find("url").exists()).toBe(false);
  expect(wrapper.find("videoKey").exists()).toBe(false);
});

test("Frame renders with the correct map prop", () => {
  const wrapper = mount(Frames, {
    shallow: true,
    props: {
      variant: "map",
      url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d337.7760656845815!2d8.531830487077526!3d47.368848456348815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a071e8f24ff%3A0x6867c75b3f405ac6!2sGENTWO%20AG!5e0!3m2!1sen!2suk!4v1618311593589!5m2!1sen!2suk",
    },
  });

  expect(wrapper.props("variant")).toBe("map");
  expect(wrapper.props("url")).toBe(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d337.7760656845815!2d8.531830487077526!3d47.368848456348815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a071e8f24ff%3A0x6867c75b3f405ac6!2sGENTWO%20AG!5e0!3m2!1sen!2suk!4v1618311593589!5m2!1sen!2suk"
  );
});

test("Frame renders with the correct wistia prop", () => {
  const wrapper = mount(Frames, {
    shallow: true,
    props: { variant: "wistia", url: "ea3hqz5q8r" },
  });

  expect(wrapper.props("variant")).toBe("wistia");
  expect(wrapper.props("url")).toBe("ea3hqz5q8r");
});

test("Frame renders with the correct vimeo prop", () => {
  const wrapper = mount(Frames, {
    shallow: true,
    props: {
      variant: "vimeo",
      url: "https://player.vimeo.com/video/217514831?background=1&quality=1080p",
    },
  });

  expect(wrapper.props("variant")).toBe("vimeo");
  expect(wrapper.props("url")).toBe(
    "https://player.vimeo.com/video/217514831?background=1&quality=1080p"
  );
});

test("Frame renders with no prop but displays the informative message", () => {
  const wrapper = mount(Frames, {
    shallow: true,
    props: {
      variant: "",
      url: "",
    },
  });

  expect(wrapper.props("variant")).toBe("");
  expect(wrapper.props("url")).toBe("");
  expect(wrapper.find("Headline").exists()).toBe(true);
  expect(wrapper.html()).toContain("Please add the variant prop to render");
});
