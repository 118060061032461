import { default as _91slug_938tGCft0aMGMeta } from "/usr/src/app/pages/[slug].vue?macro=true";
import { default as _91slug_93Twh1blqWoVMeta } from "/usr/src/app/pages/articles/[slug].vue?macro=true";
import { default as indexWgIXlS0zz8Meta } from "/usr/src/app/pages/articles/index.vue?macro=true";
import { default as _91slug_93wt8oYBHaxOMeta } from "/usr/src/app/pages/assets/[slug].vue?macro=true";
import { default as _91slug_93RHs0asjAe3Meta } from "/usr/src/app/pages/careers/[slug].vue?macro=true";
import { default as indexkjUJmb9riYMeta } from "/usr/src/app/pages/careers/index.vue?macro=true";
import { default as _91slug_93yXhZsvRYnaMeta } from "/usr/src/app/pages/communications/[slug].vue?macro=true";
import { default as index05EMGTogMgMeta } from "/usr/src/app/pages/communications/index.vue?macro=true";
import { default as _91slug_935UdTSTZYJvMeta } from "/usr/src/app/pages/company/[slug].vue?macro=true";
import { default as indexXbWGIOdM2wMeta } from "/usr/src/app/pages/company/glossary/index.vue?macro=true";
import { default as singleOgCv6AMtSvMeta } from "/usr/src/app/pages/company/glossary/single.vue?macro=true";
import { default as cookie_45settingsuPGBx8BXmmMeta } from "/usr/src/app/pages/cookie-settings.vue?macro=true";
import { default as _91slug_93fjJDMrSq45Meta } from "/usr/src/app/pages/ecosystem/[slug].vue?macro=true";
import { default as partnersVIKOK0udVRMeta } from "/usr/src/app/pages/ecosystem/partners.vue?macro=true";
import { default as _91slug_930s0AfP93HYMeta } from "/usr/src/app/pages/events/[slug].vue?macro=true";
import { default as indexTEkxEEYOOXMeta } from "/usr/src/app/pages/events/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91slug_93mcSpKNCDrnMeta } from "/usr/src/app/pages/product-spotlights/[slug].vue?macro=true";
import { default as indexfrCyvyh4NXMeta } from "/usr/src/app/pages/product-spotlights/index.vue?macro=true";
import { default as _91slug_931OVsPvgZrdMeta } from "/usr/src/app/pages/resources/webinars/[slug].vue?macro=true";
import { default as index17GUxzMcCPMeta } from "/usr/src/app/pages/resources/webinars/index.vue?macro=true";
import { default as _91slug_93Iouo2pcFl9Meta } from "/usr/src/app/pages/scenarios/[slug].vue?macro=true";
import { default as indexZ0HjUMxK0aMeta } from "/usr/src/app/pages/scenarios/index.vue?macro=true";
import { default as _91slug_93dSOvAwDJzfMeta } from "/usr/src/app/pages/solutions/[slug].vue?macro=true";
export default [
  {
    name: _91slug_938tGCft0aMGMeta?.name ?? "slug",
    path: _91slug_938tGCft0aMGMeta?.path ?? "/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    alias: _91slug_938tGCft0aMGMeta?.alias || [],
    redirect: _91slug_938tGCft0aMGMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Twh1blqWoVMeta?.name ?? "articles-slug",
    path: _91slug_93Twh1blqWoVMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93Twh1blqWoVMeta || {},
    alias: _91slug_93Twh1blqWoVMeta?.alias || [],
    redirect: _91slug_93Twh1blqWoVMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWgIXlS0zz8Meta?.name ?? "articles",
    path: indexWgIXlS0zz8Meta?.path ?? "/articles",
    meta: indexWgIXlS0zz8Meta || {},
    alias: indexWgIXlS0zz8Meta?.alias || [],
    redirect: indexWgIXlS0zz8Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wt8oYBHaxOMeta?.name ?? "assets-slug",
    path: _91slug_93wt8oYBHaxOMeta?.path ?? "/assets/:slug()",
    meta: _91slug_93wt8oYBHaxOMeta || {},
    alias: _91slug_93wt8oYBHaxOMeta?.alias || [],
    redirect: _91slug_93wt8oYBHaxOMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/assets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RHs0asjAe3Meta?.name ?? "careers-slug",
    path: _91slug_93RHs0asjAe3Meta?.path ?? "/careers/:slug()",
    meta: _91slug_93RHs0asjAe3Meta || {},
    alias: _91slug_93RHs0asjAe3Meta?.alias || [],
    redirect: _91slug_93RHs0asjAe3Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/careers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkjUJmb9riYMeta?.name ?? "careers",
    path: indexkjUJmb9riYMeta?.path ?? "/careers",
    meta: indexkjUJmb9riYMeta || {},
    alias: indexkjUJmb9riYMeta?.alias || [],
    redirect: indexkjUJmb9riYMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yXhZsvRYnaMeta?.name ?? "communications-slug",
    path: _91slug_93yXhZsvRYnaMeta?.path ?? "/communications/:slug()",
    meta: _91slug_93yXhZsvRYnaMeta || {},
    alias: _91slug_93yXhZsvRYnaMeta?.alias || [],
    redirect: _91slug_93yXhZsvRYnaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/communications/[slug].vue").then(m => m.default || m)
  },
  {
    name: index05EMGTogMgMeta?.name ?? "communications",
    path: index05EMGTogMgMeta?.path ?? "/communications",
    meta: index05EMGTogMgMeta || {},
    alias: index05EMGTogMgMeta?.alias || [],
    redirect: index05EMGTogMgMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/communications/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935UdTSTZYJvMeta?.name ?? "company-slug",
    path: _91slug_935UdTSTZYJvMeta?.path ?? "/company/:slug()",
    meta: _91slug_935UdTSTZYJvMeta || {},
    alias: _91slug_935UdTSTZYJvMeta?.alias || [],
    redirect: _91slug_935UdTSTZYJvMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXbWGIOdM2wMeta?.name ?? "company-glossary",
    path: indexXbWGIOdM2wMeta?.path ?? "/company/glossary",
    meta: indexXbWGIOdM2wMeta || {},
    alias: indexXbWGIOdM2wMeta?.alias || [],
    redirect: indexXbWGIOdM2wMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/company/glossary/index.vue").then(m => m.default || m)
  },
  {
    name: singleOgCv6AMtSvMeta?.name ?? "company-glossary-single",
    path: singleOgCv6AMtSvMeta?.path ?? "/company/glossary/single",
    meta: singleOgCv6AMtSvMeta || {},
    alias: singleOgCv6AMtSvMeta?.alias || [],
    redirect: singleOgCv6AMtSvMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/company/glossary/single.vue").then(m => m.default || m)
  },
  {
    name: cookie_45settingsuPGBx8BXmmMeta?.name ?? "cookie-settings",
    path: cookie_45settingsuPGBx8BXmmMeta?.path ?? "/cookie-settings",
    meta: cookie_45settingsuPGBx8BXmmMeta || {},
    alias: cookie_45settingsuPGBx8BXmmMeta?.alias || [],
    redirect: cookie_45settingsuPGBx8BXmmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/cookie-settings.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fjJDMrSq45Meta?.name ?? "ecosystem-slug",
    path: _91slug_93fjJDMrSq45Meta?.path ?? "/ecosystem/:slug()",
    meta: _91slug_93fjJDMrSq45Meta || {},
    alias: _91slug_93fjJDMrSq45Meta?.alias || [],
    redirect: _91slug_93fjJDMrSq45Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/ecosystem/[slug].vue").then(m => m.default || m)
  },
  {
    name: partnersVIKOK0udVRMeta?.name ?? "ecosystem-partners",
    path: partnersVIKOK0udVRMeta?.path ?? "/ecosystem/partners",
    meta: partnersVIKOK0udVRMeta || {},
    alias: partnersVIKOK0udVRMeta?.alias || [],
    redirect: partnersVIKOK0udVRMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/ecosystem/partners.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930s0AfP93HYMeta?.name ?? "events-slug",
    path: _91slug_930s0AfP93HYMeta?.path ?? "/events/:slug()",
    meta: _91slug_930s0AfP93HYMeta || {},
    alias: _91slug_930s0AfP93HYMeta?.alias || [],
    redirect: _91slug_930s0AfP93HYMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTEkxEEYOOXMeta?.name ?? "events",
    path: indexTEkxEEYOOXMeta?.path ?? "/events",
    meta: indexTEkxEEYOOXMeta || {},
    alias: indexTEkxEEYOOXMeta?.alias || [],
    redirect: indexTEkxEEYOOXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mcSpKNCDrnMeta?.name ?? "product-spotlights-slug",
    path: _91slug_93mcSpKNCDrnMeta?.path ?? "/product-spotlights/:slug()",
    meta: _91slug_93mcSpKNCDrnMeta || {},
    alias: _91slug_93mcSpKNCDrnMeta?.alias || [],
    redirect: _91slug_93mcSpKNCDrnMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/product-spotlights/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfrCyvyh4NXMeta?.name ?? "product-spotlights",
    path: indexfrCyvyh4NXMeta?.path ?? "/product-spotlights",
    meta: indexfrCyvyh4NXMeta || {},
    alias: indexfrCyvyh4NXMeta?.alias || [],
    redirect: indexfrCyvyh4NXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/product-spotlights/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931OVsPvgZrdMeta?.name ?? "resources-webinars-slug",
    path: _91slug_931OVsPvgZrdMeta?.path ?? "/resources/webinars/:slug()",
    meta: _91slug_931OVsPvgZrdMeta || {},
    alias: _91slug_931OVsPvgZrdMeta?.alias || [],
    redirect: _91slug_931OVsPvgZrdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/resources/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: index17GUxzMcCPMeta?.name ?? "resources-webinars",
    path: index17GUxzMcCPMeta?.path ?? "/resources/webinars",
    meta: index17GUxzMcCPMeta || {},
    alias: index17GUxzMcCPMeta?.alias || [],
    redirect: index17GUxzMcCPMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/resources/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Iouo2pcFl9Meta?.name ?? "scenarios-slug",
    path: _91slug_93Iouo2pcFl9Meta?.path ?? "/scenarios/:slug()",
    meta: _91slug_93Iouo2pcFl9Meta || {},
    alias: _91slug_93Iouo2pcFl9Meta?.alias || [],
    redirect: _91slug_93Iouo2pcFl9Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/scenarios/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZ0HjUMxK0aMeta?.name ?? "scenarios",
    path: indexZ0HjUMxK0aMeta?.path ?? "/scenarios",
    meta: indexZ0HjUMxK0aMeta || {},
    alias: indexZ0HjUMxK0aMeta?.alias || [],
    redirect: indexZ0HjUMxK0aMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/scenarios/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dSOvAwDJzfMeta?.name ?? "solutions-slug",
    path: _91slug_93dSOvAwDJzfMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93dSOvAwDJzfMeta || {},
    alias: _91slug_93dSOvAwDJzfMeta?.alias || [],
    redirect: _91slug_93dSOvAwDJzfMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/solutions/[slug].vue").then(m => m.default || m)
  }
]