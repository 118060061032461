<template>
  <div
    :class="[
      variantClass,
      'grid w-full  gap-4 lg:grid-cols-12 lg:gap-5',
      { 'grid-cols-6': isFullBleed },
    ]"
  >
    <slot v-if="isFullBleed" />
    <div
      v-else
      class="grid w-full grid-cols-4 gap-4 px-4 sm:grid-cols-8 lg:col-start-3 lg:col-end-11 lg:gap-5 lg:px-0"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed as vComputed, PropType } from "vue";
import { variantClasses } from "./const";
import { variantType } from "./types";
const props = defineProps({
  variant: {
    type: String as PropType<variantType>,
    required: true,
  },
});

const isFullBleed = vComputed<boolean>(() => props.variant === "fullBleed");
const variantClass = vComputed<string>(() => variantClasses[props.variant]);
</script>
