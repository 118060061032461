export const icons = [
  "Facebook",
  "Twitter",
  "Linkedin",
  "Email",
  "Location",
  "Instagram",
  "Youtube",
] as const;
export const navigate = ["share", "redirect"] as const;

export const sharedLinks: { [key: string]: string } = {
  Facebook: "https://www.facebook.com/share.php?u=",
  Twitter: "https://twitter.com/intent/tweet?text=",
  Linkedin: "https://www.linkedin.com/shareArticle?url=",
};
