interface HeadingVariant {
  [key: string]: string;
}

export const levels = ["h1", "h2", "h3", "h4", "h5", "h6", "p"] as const;
// Usage for Storybook
export const headingVariants = [
  "xxsmall",
  "xsmall",
  "small",
  "normal",
  "large",
  "xlarge",
  "xxlarge",
];
export const capitalVariants = ["capitalXSmall", "capitalSmall", "capital"];
export const bodyVariants = ["bodySmall", "body", "bodyLarge", "bodyXLarge"];

// Usage for template
export const variants = [
  ...headingVariants,
  ...capitalVariants,
  ...bodyVariants,
] as const;

export const variantClasses = {
  xxsmall: "text-xxsmall",
  xsmall: "text-xsmall",
  small: "text-xsmall 2xl:text-small",
  normal: "text-small 2xl:text-normal",
  large: "text-small sm:text-large",
  xlarge: "text-bodyXXLarge lg:text-large 2xl:text-xlarge",
  xxlarge: "text-normal lg:text-xlarge 2xl:text-xxlarge",
  capitalXSmall: "text-capitalXSmall uppercase",
  capitalSmall: "text-capitalXSmall sm:text-capitalSmall uppercase",
  capital: "text-capital uppercase",
  bodySmall: "text-bodySmall",
  body: "text-body",
  bodyLarge: "text-bodyLarge",
  bodyXLarge: "text-bodyLarge sm:text-bodyXLarge",
  bodyXXLarge: "text-bodyXXLarge",
} as HeadingVariant;
