export const defaultHeadlineProps = {
  level: "h3",
  variant: "normal",
  headlineClass: undefined,
  text: undefined,
};

export const defaultSubTitleProps = {
  headlineText: undefined,
  dateHeadlineText: undefined,
  headlioneIcon: undefined,
  dateHeadlineIcon: undefined,
};

export const defaultTagProps = {
  variant: "default",
  titleColor: undefined,
  backgroundColor: undefined,
  href: undefined,
  text: undefined,
};

export const defaultStrapiButtonProps = {
  url: undefined,
  text: undefined,
  newTab: undefined,
  variant: "primary",
  icon: undefined,
  underline: false,
  compact: false,
  reverse: false,
  disabled: false,
  customStyle: "padding: 0 0 0 32px;",
  target: "_blank",
};

export const defaultImageProps = {
  url: undefined,
  width: "auto",
  height: "auto",
  imgClasses: undefined,
};
