import { SplideOptions } from "~/components/BaseSlider/types";

export const slideComponentName = "SplideSlide";

export const variants = ["arrows", "pagination", undefined] as const;
export const positions = ["left", "center", "right"] as const;

export const splideDefaultOptions: SplideOptions = {
  speed: 1000,
  // type: "loop",
  autoplay: true,
  // autoWidth: true,
  interval: 5000,
  rewind: true,
  perMove: 1,
  perPage: 2,
  breakpoints: {
    1024: { perPage: 2 },
    640: { perPage: 1 },
  },
  classes: {
    pagination: "splide__pagination justify-start",
  },
  padding: { left: "1rem", right: "2rem" },
};
