<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 301 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M108.504 216.284C79.4602 194.359 60.6798 159.526 60.6798 120.296C60.6798 120.182 60.6798 120.068 60.6798 119.96C27.5517 119.96 0.697754 146.852 0.697754 180.027V240.094H60.6798C80.204 240.094 97.5508 230.748 108.504 216.284Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M120.662 180.027C120.662 193.65 116.133 206.21 108.503 216.284C128.579 231.439 153.562 240.431 180.644 240.431C246.9 240.431 300.608 186.646 300.608 120.296V0.161469H180.644C114.502 0.161469 60.8657 53.7655 60.6858 119.96C93.8139 119.96 120.662 146.852 120.662 180.027Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M108.504 216.284C116.133 206.204 120.662 193.644 120.662 180.027C120.662 146.852 93.8079 119.96 60.6858 119.96C60.6858 120.074 60.6858 120.188 60.6858 120.296C60.6858 159.526 79.4662 194.359 108.51 216.284H108.504Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
