type chipStyleVariants = {
  [key: string]: string;
};
interface Variants {
  [key: string]: chipStyleVariants;
}

export const chipClasses = {
  default: {
    chip: "bg-black-600 border-b-4 px-1 py-4 sm:justify-center border-black-600",
    active: "bg-black-500 border-white",
    icon: "sm:pb-3.5 sm:pt-4",
  },
  borderList: {
    chip: "bg-transparent px-5 pb-1 pt-2 sm:justify-between border-0 w-full !px-0",
    active: "bg-black-500 border-white",
    icon: "sm:py-5",
  },
} as Variants;

export const chips = [
  {
    id: 1,
    text: "Art",
    isActive: false,
    icon: "Global",
  },
  {
    id: 2,
    text: "Bonds",
    isActive: false,
    icon: "Bonds",
  },
  {
    id: 3,
    text: "Cash",
    isActive: false,
    icon: "Dollar",
  },
  {
    id: 4,
    text: "Collectables",
    isActive: false,
    icon: "Coin",
  },
  {
    id: 5,
    text: "Commodities",
    isActive: false,
    icon: "Trade",
  },
  {
    id: 6,
    text: "Crypto",
    isActive: false,
    icon: "Bitcoin",
  },
  {
    id: 7,
    text: "Derivates",
    isActive: false,
    icon: "Wallet",
  },
  {
    id: 8,
    text: "Equity",
    isActive: false,
    icon: "Wallet",
  },
];
