<template>
  <div v-if="hubspot" class="text-black-200" :class="checkHeadline">
    <HeadlineGroup v-if="headlineGroup" v-bind="headlineGroup" />
    <div :class="hubspot.formHash"></div>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted as vOnMounted,
  PropType,
  ref as vRef,
  computed as vComputed,
} from "vue";
import * as Cookie from "tiny-cookie";
import { hubspotCSS } from "./const";
import HeadlineGroup from "~/components/Headline/HeadlineGroup.vue";
import { HeadlineProps } from "~/components/Headline/types";
import { Actions } from "~/types/gtag";
import { trackEvent } from "~/utils/gtag";

interface GtagAttribute {
  gclid: string;
  utm_campaign: string;
  utm_source: string;
  utm_source_detail: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
}

const props = defineProps({
  headlineGroup: {
    type: Object as PropType<HeadlineProps>,
    default: null,
  },
  // eslint-disable-next-line vue/require-default-prop
  hubspot: Object as PropType<{
    region: string;
    portalId: string;
    formId: string;
    formHash: string;
  }>,
});

const flagTheTracking = vRef(false);
const downloadPresentation = vRef(false);

const checkHeadline = vComputed(() =>
  props.headlineGroup ? "flex flex-col gap-10" : "gap-0"
);

const updateFieldValue = (fieldName: string, fieldValue: string) => {
  const existingField = document.querySelector(`[name="${fieldName}"]`);
  if (existingField instanceof HTMLInputElement) {
    existingField.value = fieldValue;
  }
};
const emit = defineEmits(["downloadPresentation"]);
function onDownloadPresentation() {
  downloadPresentation.value = true;
  emit("downloadPresentation", downloadPresentation.value);
}

const setUserSubscribedCookie = () => {
  Cookie.setCookie("user_subscribed", "true", { expires: "6M" });
};

vOnMounted(() => {
  const script = document.createElement("script");
  script.src = "//js.hsforms.net/forms/v2.js";
  script.onload = () => {
    (window as unknown).hbspt.forms.create({
      region: props.hubspot?.region,
      portalId: props.hubspot?.portalId,
      formId: props.hubspot?.formId,
      target: `.${props.hubspot?.formHash}`,
      css: hubspotCSS,
      onFormReady: () => {
        if (Cookie.get("_ga") !== null) {
          updateFieldValue("gaclientid", Cookie.get("_ga")!);
        }
        if (Cookie.get("_att") !== null) {
          const attObj: GtagAttribute = JSON.parse(Cookie.get("_att")!);
          const fieldMappings: Record<keyof GtagAttribute, string> = {
            gclid: "gclid",
            utm_campaign: "campaign",
            utm_source: "contact_source",
            utm_source_detail: "contact_source_detail",
            utm_medium: "utm_medium",
            utm_term: "utm_term",
            utm_content: "utm_content",
          };

          for (const key in attObj) {
            if (
              Object.prototype.hasOwnProperty.call(attObj, key) &&
              fieldMappings[key as keyof GtagAttribute]
            ) {
              const value = attObj[key as keyof GtagAttribute];
              if (value) {
                updateFieldValue(
                  fieldMappings[key as keyof GtagAttribute],
                  value.replace(/"/g, "")
                );
              }
            }
          }
        }
      },
      onBeforeFormSubmit: () => {
        const selectElement: HTMLSelectElement | null = document.querySelector(
          'select[name="i_want_to_"]'
        );
        if (selectElement?.value === "Learn more about GenTwo") {
          flagTheTracking.value = true;
        }
      },
      onFormSubmitted: () => {
        flagTheTracking.value &&
          trackEvent({
            name: Actions.CONTACT_US,
            campaignAttributes: true,
          });
        onDownloadPresentation();
        Cookie.set("user_submitted_form", "true");
        if (Cookie.get("_att")) {
          Cookie.remove("_att");
        }
        if (
          props.hubspot?.formId === "a7edb402-fc39-4dfb-ae71-e5bfb99f6a61" &&
          !Cookie.get("user_subscribed")
        ) {
          setUserSubscribedCookie();
        }
      },
    });
  };
  document.head.appendChild(script);
});
</script>
