interface Variant {
  [key: string]: string;
}

export const variants = ["fullBleed", "guttersOnly"] as const;

export const variantClasses = {
  fullBleed: "gap-4 grid-cols-4 px-4 xl:px-0",
  guttersOnly: "grid-cols-1",
} as Variant;
