import dayjs from "dayjs";
import { templateInterface } from "~/types";

export function getFormattedDate(template: templateInterface[]) {
  const { date } = template[0]["headline-main"];
  return {
    date: dayjs(date).format("DD.MM.YYYY"),
    time: dayjs(date).format("HH:mm"),
  };
}

export function getLongDate(date: Date, format: string = "DD MMMM YYYY") {
  if (!date) return;
  return dayjs(date).format(format);
}
