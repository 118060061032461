import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Ticker from "../Ticker.vue";

const chooseOtherLogos = [
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbJVpOdtk96FIiNKr9o2eVdz6pebpXb2n3UO6MjRDGABy-1EoDIjhwog9ACA7ez0RYH7Q&usqp=CAU",
  "https://cdn.xxl.thumbs.canstockphoto.com/test-concept-picture_csp17279717.jpg",
];

test("Ticker renders custom images", async () => {
  const wrapper = mount(Ticker, {
    props: {
      logos: chooseOtherLogos,
    },
  });
  await wrapper.setProps({ logos: chooseOtherLogos });
  expect(wrapper.props()).toEqual({
    logos: chooseOtherLogos,
  });
});
