<template>
  <div :class="baseClass" :data-comp="baseClass">
    <Component
      :is="isLink"
      :href="linkProps"
      :class="[
        'flex w-fit items-center justify-center border-2 border-transparent leading-none',
        isDefaultTag
          ? [selectedBg, selectedTagVariant, selectedColor]
          : selectedTagVariant,
        selectedColor,
        contentClass ?? 'text-body',
      ]"
    >
      <slot></slot>
      <span class="mt-0.5">{{ text }}</span>
    </Component>
  </div>
</template>

<script setup lang="ts">
import { PropType, toRefs as vToRefs, computed as vComputed } from "vue";
import {
  variantClasses,
  titleColorClasses,
  backgroundClasses,
} from "~/components/Tags/const";
import { variants, colors, background } from "~/components/Tags/types";

const baseClass = "gt-tag";

const props = defineProps({
  variant: {
    type: String as PropType<variants>,
    default: "default",
  },
  titleColor: {
    type: String as PropType<colors>,
    default: "",
  },
  backgroundColor: {
    type: String as PropType<background>,
    default: "orange",
  },
  href: {
    type: String as PropType<String>,
    default: "",
  },
  text: {
    type: String,
    required: true,
    default: "Default Text",
  },
  contentClass: {
    type: String,
    default: "",
  },
});

const { variant, titleColor, backgroundColor, href } = vToRefs(props);
const selectedTagVariant = vComputed<string>(
  () => variantClasses[variant?.value]
);
const selectedColor = vComputed<string>(
  () => titleColorClasses[titleColor?.value]
);

const selectedBg = vComputed<string>(
  () => backgroundClasses[backgroundColor?.value]
);

const isLink = vComputed<string>(() =>
  variant?.value === "linkTag" && href?.value ? "a" : "div"
);
const linkProps = vComputed(() =>
  variant?.value === "linkTag" && href?.value ? href.value : null
);
const isDefaultTag = vComputed<boolean>(() => variant?.value === "default");
</script>
