import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const config = useRuntimeConfig();
    if (config.public.environment !== "local") {
      Sentry.init({
        app: vueApp,
        dsn: config.public.sentryDsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        environment: config.public.environment,
      });
    }
  },
});
