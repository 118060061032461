import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Accordion from "~/components/Accordion/Accordion.vue";

test("Accordion Snapshot UI testing", () => {
  const wrapper = mount(Accordion);
  expect(wrapper.html()).toMatchSnapshot();
});

test("Accordion renders correctly with default props", async () => {
  expect(Accordion).toBeTruthy(Accordion);

  const wrapper = mount(Accordion);

  expect(wrapper.props()).toEqual({
    title: undefined,
    open: false,
  });

  await wrapper.setProps({ title: "Title Changed" });
  expect(wrapper.html()).toContain("Title Changed");

  await wrapper.setProps({ slot: "Text Changed" });
  expect(wrapper.html()).toContain("Text Changed");
});

test("Accordion expanded", () => {
  expect(Accordion).toBeTruthy(Accordion);

  const wrapper = mount(Accordion, {
    props: { open: true, title: "title example" },
  });

  expect(wrapper.find("details").attributes("open")).toBe("true");
});
