<template>
  <svg
    width="524"
    height="186"
    viewBox="0 0 524 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M101.338 183.88V137.032H0.985962L129.498 0.071991V111.688H151.514V137.032H129.498V183.88H101.338ZM55.77 111.688H101.338V62.792L55.77 111.688Z"
      fill="white"
    />
    <path
      d="M266.416 185.928C253.445 185.928 241.328 183.539 230.064 178.76C218.8 173.981 208.901 167.411 200.368 159.048C191.835 150.515 185.179 140.616 180.4 129.352C175.621 118.088 173.232 105.971 173.232 93C173.232 80.0293 175.621 67.912 180.4 56.648C185.179 45.384 191.835 35.5707 200.368 27.208C208.901 18.6747 218.8 12.0187 230.064 7.23999C241.328 2.46132 253.445 0.071991 266.416 0.071991C279.387 0.071991 291.504 2.46132 302.768 7.23999C314.032 12.0187 323.931 18.6747 332.464 27.208C340.997 35.5707 347.653 45.384 352.432 56.648C357.381 67.912 359.856 80.0293 359.856 93C359.856 105.971 357.381 118.088 352.432 129.352C347.653 140.616 340.997 150.515 332.464 159.048C323.931 167.411 314.032 173.981 302.768 178.76C291.504 183.539 279.387 185.928 266.416 185.928ZM266.416 159.816C279.045 159.816 290.224 156.829 299.952 150.856C309.68 144.883 317.36 136.861 322.992 126.792C328.624 116.723 331.44 105.459 331.44 93C331.44 80.5413 328.624 69.2773 322.992 59.208C317.36 49.1387 309.68 41.1173 299.952 35.144C290.224 29.1707 279.045 26.184 266.416 26.184C253.957 26.184 242.779 29.1707 232.88 35.144C223.152 41.1173 215.472 49.1387 209.84 59.208C204.379 69.2773 201.648 80.5413 201.648 93C201.648 105.459 204.379 116.723 209.84 126.792C215.472 136.861 223.152 144.883 232.88 150.856C242.779 156.829 253.957 159.816 266.416 159.816Z"
      fill="white"
    />
    <path
      d="M472.838 183.88V137.032H372.486L500.998 0.071991V111.688H523.014V137.032H500.998V183.88H472.838ZM427.27 111.688H472.838V62.792L427.27 111.688Z"
      fill="white"
    />
    <ellipse cx="269.274" cy="93" rx="47.3121" ry="22.6568" fill="#F346F7" />
    <circle cx="269.274" cy="93" r="13" fill="#0C0D0E" />
    <circle cx="262.577" cy="86.5188" r="2.5" fill="#E8E9E9" />
  </svg>
</template>
