export const iconNames = [
  "Twitter",
  "Linkedin",
  "Email",
  "Facebook",
  "Instagram",
  "Youtube",
  "Dollar",
  "Etherium",
  "EyeIcon",
  "EyeIconClosed",
  "LogoIcon",
  "PlayButton",
  "Spinner",
];

export const materialIconNames = [
  "expand_more",
  "expand_less",
  "navigate_next",
  "close",
  "cancel",
  "Search",
  "arrow_forward",
  "arrow_back",
  "arrow_upward",
  "arrow_outward",
  "forward",
  "work_history",
  "download",
  "all_inclusive",
  "bolt",
  "star",
  "lightbulb",
  "settings",
  "grid_view",
  "deployed_code",
  "add",
  "monitoring",
  "swap_vertical_circle",
  "show_chart",
  "change_history",
  "location_on",
  "app_registration",
  "person_apron",
  "language",
  "health_and_safety",
  "emergency",
  "currency_bitcoin",
  "phone_in_talk",
  "mail",
  "send",
  "visibility",
  "visibility_off",
  "data_check",
  "new_releases",
  "schedule",
  "history",
  "filter_alt",
  "filter_list",
  "play_circle",
  "play_arrow",
  "skip_next",
  "replay",
  "smart_display",
  "skip_previous",
  "wallet",
  "sync",
  "right_click",
  "bottom_right_click",
  "arrow_outward",
  "call",
  "check_circle",
  "home_pin",
  "account_balance_wallet",
  "universal_currency_alt",
  "dashboard",
  "join_right",
  "next_week",
  "waterfall_chart",
  "token",
  "offline_bolt",
];

export const dimensions = [
  "small",
  "base",
  "medium",
  "large",
  "default",
  "xlarge",
];
export const dimensionsMap = {
  small: 16,
  base: 18,
  medium: 20,
  large: 24,
  default: 32,
  xlarge: 42,
};
