// TODO Nuxt-Vue-migration:
// Use @nuxtjs/gtm (https://github.com/nuxt-community/gtm-module) instead of @gtm-support/vue-gtm if support for Nuxt 3 becomes available.
// https://github.com/nuxt-community/gtm-module/issues/152
import { useRouter as vUseRouter } from "vue-router";
import { createGtm } from "@gtm-support/vue-gtm";
import * as Cookie from "tiny-cookie";

export default defineNuxtPlugin((nuxtApp) => {
  const { googleTagManagerId, environment } = useRuntimeConfig().public;
  if (!googleTagManagerId) {
    return;
  }

  const isCookieAccepted = Cookie.get("GentwoTrackingConsent") === "true";
  const router = vUseRouter();

  nuxtApp.vueApp.use(
    createGtm({
      id: googleTagManagerId,
      defer: false,
      compatibility: false,
      enabled: isCookieAccepted,
      debug: environment === "local",
      // TODO: setting `loadScript: true` causes errors in the browser console
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    })
  );
});
