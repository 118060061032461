import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import BaseSlider from "~/components/BaseSlider/BaseSlider.vue";
import { splideDefaultOptions } from "~/components/BaseSlider/const";
import { SplideOptions } from "~/components/BaseSlider/types";

test("BaseSlider Snapshot UI testing", () => {
  const wrapper = mount(BaseSlider);
  expect(wrapper.text()).toMatchSnapshot();
});

const sliderOptions: SplideOptions = {
  speed: 600,
  autoplay: true,
  interval: 6000,
  rewind: true,
  perMove: 1,
  perPage: 2,
  breakpoints: {
    1024: { perPage: 2 },
    640: { perPage: 1 },
  },
};

test("BaseSlider renders correctly with default props", async () => {
  expect(BaseSlider).toBeTruthy();

  const wrapper = mount(BaseSlider);

  expect(wrapper.props()).toEqual({
    options: splideDefaultOptions,
    variant: undefined,
  });

  await wrapper.setProps({ options: sliderOptions });
  expect(wrapper.props("options")).toEqual(sliderOptions);

  await wrapper.setProps({ variant: "pagination" });
  expect(wrapper.props("variant")).toBe("pagination");
});

test("BaseSlider renders with the correct children", () => {
  const wrapper = mount(BaseSlider, {
    slots: {
      default: "<div>This is a random text</div>",
    },
  });

  expect(wrapper.html()).toContain("<div>This is a random text</div>");
});
