import { useWindowSize } from "@vueuse/core";
import { SplideOptions } from "~/components/BaseSlider/types";
const { width } = useWindowSize();

export function getSliderItemsPerPage(sliderOptions: SplideOptions) {
  return (
    (width.value > 640
      ? sliderOptions?.breakpoints?.["1024"].perPage
      : sliderOptions?.breakpoints?.["600"].perPage) ??
    sliderOptions.perPage ??
    1
  );
}
