<template>
  <div :class="baseClass" :data-comp="baseClass">
    <Component :is="level" :class="[variantClass, headlineClass]">
      <slot></slot>
      {{ props.text }}
    </Component>
  </div>
</template>

<script setup lang="ts">
import { computed as vComputed, PropType, toRefs as vToRefs } from "vue";
import { variantClasses } from "~/components/Headline/const";
import { levels, variants } from "~/components/Headline/types";

const baseClass = "gt-Headline";

const props = defineProps({
  level: {
    type: String as PropType<levels>,
    default: "h3",
  },
  variant: {
    type: String as PropType<variants>,
    default: "normal",
  },
  headlineClass: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    required: true,
    default: "The quick brown fox jumps over the lazy dog",
  },
});

const { level, variant } = vToRefs(props);

const variantClass = vComputed<string>(() => variantClasses[variant?.value]);
</script>
