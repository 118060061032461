<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 301 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M60.7697 240.223V120.708C60.7697 120.594 60.7697 120.48 60.7697 120.373C27.6416 120.373 0.787598 147.201 0.787598 180.298C0.787598 213.395 27.6416 240.223 60.7697 240.223Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M120.752 180.298V240.223H60.7697V240.559H180.734C246.99 240.559 300.698 186.902 300.698 120.708C300.698 54.5147 246.99 0.857605 180.734 0.857605C114.478 0.857605 60.9556 54.3349 60.7757 120.373C93.9038 120.373 120.752 147.201 120.752 180.298Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M60.7697 240.223H120.752V180.298C120.752 147.201 93.8978 120.373 60.7757 120.373C60.7757 120.486 60.7757 120.6 60.7757 120.708V240.223H60.7697Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
