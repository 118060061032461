<template>
  <component
    :is="iconComponent"
    :class="baseClass"
    :data-comp="baseClass"
    v-bind="props"
  >
  </component>
</template>

<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import * as Icons from "../Icons";
import { IconDimension, IconName } from "./types";

const baseClass = "gt-icon";

const props = defineProps({
  name: {
    type: String as PropType<IconName>,
    required: true,
  },
  size: {
    type: String as PropType<IconDimension>,
    default: "large",
  },
  alt: {
    type: String,
    default: "",
  },
  ariaLabel: {
    type: String,
    default: "",
  },
  ariaHidden: {
    type: Boolean,
    default: false,
  },
  tabIndex: {
    type: Number,
    default: null,
  },
  iconColor: {
    type: String,
    default: "white",
  },
});

const iconComponent = vComputed(() => {
  /* eslint import/namespace: ['error', { allowComputed: true }] */
  return Icons[props.name];
});
</script>
