export const buttonVariants = [
  "primary",
  "secondary",
  "ghost",
  "gradient",
] as const;

export const buttonClasses = {
  primary:
    "bg-white text-black-900 border-transparent  transition duration-300 hover:bg-black-50 disabled:bg-black-100 disabled:cursor-not-allowed disabled:text-black-500",
  secondary:
    "bg-transparent text-white border-white transition duration-300 hover:bg-white hover:text-black-700 disabled:border-black-200 disabled:bg-transparent disabled:cursor-not-allowed disabled:text-black-100",
  ghost:
    "bg-transparent text-white border-transparent hover:text-black-50 disabled:text-black-100 disabled:cursor-not-allowed",
  gradient:
    "bg-transparent text-white border-gradient-mango transition duration-300 hover:bg-white hover:text-black-700 disabled:border-black-200 disabled:bg-transparent disabled:cursor-not-allowed disabled:text-black-100",
} as const;
