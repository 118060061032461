import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import ChipsList from "~/components/Chip/ChipsList.vue";
import Chip from "~/components/Chip/Chip.vue";

test("ChipsList Snapshot UI testing", () => {
  const wrapper = mount(ChipsList);
  expect(wrapper.text()).toMatchSnapshot();
});

const listOfItems = [
  {
    id: 1,
    text: "All",
    isActive: true,
  },
  {
    id: 2,
    text: "Bonds",
    isActive: false,
  },
  {
    id: 3,
    text: "Cash",
    isActive: false,
  },
];

test("ChipsList renders correctly with default props", async () => {
  expect(ChipsList).toBeTruthy();

  const wrapper = mount(ChipsList, {
    itemsList: listOfItems,
  });

  expect(wrapper.props()).toEqual({
    items: [],
    listType: "slider",
  });

  await wrapper.setProps({ items: listOfItems });

  expect(wrapper.props("items")).toEqual(listOfItems);

  expect(wrapper.findComponent({ name: "BaseSlider" }).exists()).toBe(true);
});

test("Chip select click event to render correctly", async () => {
  const wrapper = mount(ChipsList, {
    props: {
      items: listOfItems,
    },
  });

  const button = wrapper.findComponent("button");

  await button.trigger("click");
});
