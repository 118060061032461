import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Testimonials from "../Testimonials.vue";
test("snapshot UI testing", () => {
  const wrapper = mount(Testimonials, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Testimonials component renders correctly with default props", async () => {
  expect(Testimonials).toBeTruthy();

  const wrapper = mount(Testimonials);

  expect(wrapper.props()).toEqual({
    authorName: undefined,
    authorPosition: "",
    title: undefined,
    authorImage: undefined,
  });

  await wrapper.setProps({ title: "Testimonial Changed" });
  expect(wrapper.html()).toContain("Testimonial Changed");
});

test("Testimonial picture has src", () => {
  const wrapper = mount(Testimonials, {
    props: {
      authorImage: {
        url: "url",
      },
    },
  });
  expect(wrapper.html()).toContain("src");
});
