import { Video } from "~/components/VideoTestimonials/types";
import { postAttributes } from "~/types";

export const Actions = {
  SIGN_UP: "Sign-up",
  CONTACT_US: "Contact-us request",
  CTA: "Button",
  WATCH_VIDEO: "Watch video",
  PAGE_DETAILS: "Page details",
} as const;

type TActions = (typeof Actions)[keyof typeof Actions];

export interface EventData {
  event?: TActions;
  journey_status?: "Registered";
  utm_source?: string;
  utm_source_detail?: string;
  utm_campaign?: string;
  gaclientID?: string;
  ui_type?: "Form" | "Call-To-Action" | "Video";
  ui_name?: "Signup form" | "Contact-us form" | string;
  action_type?: "Form submission" | "Click" | "Watch video";
  content_type?:
    | "Traditional Assets"
    | "Alternative Assets"
    | "Digital Assets"
    | string;
  page?: string;
}

export interface EventParams {
  name: TActions;
  campaignAttributes?: boolean;
  video?: Video;
  contentTypeData?: postAttributes;
  labelText?: string;
  path?: string;
}
