export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0&display=swap"}],"style":[],"script":[],"noscript":[]}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null