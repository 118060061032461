import { expect, test } from "vitest";
import { shallowMount } from "@vue/test-utils";
import Footer from "../Footer.vue";
import { links, terms, links1, terms1 } from "../const";

test("snapshot UI testing", () => {
  const wrapper = shallowMount(Footer, {
    props: {
      links: [],
      terms: [],
    },
    global: {
      components: {
        NuxtLink: {
          template: "<a><slot/></a>",
        },
      },
    },
  });
  expect(wrapper.text()).toMatchSnapshot();
});

test("Footer renders correctly with default props", () => {
  expect(Footer).toBeTruthy();

  const wrapper = shallowMount(Footer, {
    global: {
      components: {
        NuxtLink: {
          template: "<a><slot/></a>",
        },
      },
    },
  });

  expect(wrapper.props()).toEqual({
    footerItems: links,
    termItems: terms,
  });
});

test("Footer renders correctly with other props", async () => {
  expect(Footer).toBeTruthy();

  const wrapper = shallowMount(Footer, {
    global: {
      components: {
        NuxtLink: {
          template: "<a><slot/></a>",
        },
      },
    },
  });

  await wrapper.setProps({
    footerItems: links1,
    termItems: terms1,
  });
  expect(wrapper.props()).toEqual({
    footerItems: links1,
    termItems: terms1,
  });
});
