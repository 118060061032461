<template>
  <div :class="baseClass" :data-comp="baseClass" class="flex flex-col gap-6">
    <div class="flex flex-col">
      <Headline
        v-if="subtitle"
        level="p"
        :text="subtitle"
        variant="capitalSmall"
        :headline-class="`font-semibold ${
          isMain ? 'text-white' : 'text-black-300'
        }`"
      />
      <Headline
        v-if="title"
        level="h2"
        :variant="getVariantClassBasedOnVariant"
        :text="title"
        headline-class="text-white font-euclid"
      />
      <Headline
        v-if="date"
        headline-class="font-mierb font-semibold leading-none"
        level="p"
        variant="body"
        :text="getLongDate(date)"
      />
    </div>
    <div
      v-if="description"
      v-dompurify-html="description"
      class="content-description whitespace-pre-wrap font-medium text-white"
      :class="descriptionClass"
    ></div>
    <div
      v-if="buttons?.length"
      :class="{ 'flex flex-wrap gap-5': buttons?.length > 0 }"
    >
      <StrapiButton
        v-for="(button, index) in buttons"
        :key="index"
        v-bind="button"
        class="w-full justify-center sm:w-max sm:justify-start"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import type { HeadlineGroupProps } from "./types";
import type { Button } from "~/types";
import { getLongDate } from "~/utils";

const baseClass = "gt-headline-group";
const variantClass = {
  primary: "text-bodyXLarge lg:text-xsmall font-medium",
  compact: "text-bodyXLarge lg:text-xxsmall",
  main: "text-sm lg:text-small font-medium",
  organizer: "!text-bodyLarge",
};

const props: HeadlineGroupProps = defineProps({
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  date: {
    type: Date,
    default: "",
  },
  description: {
    type: String,
    required: false,
    default: "",
  },
  variant: {
    type: String as PropType<"compact" | "primary" | "main" | "organizer">,
    default: "compact",
  },
  buttons: { type: Array as Button[], default: () => {} },
});
const descriptionClass = vComputed(() => variantClass[props.variant]);
const isMain = vComputed(() => props.variant === "main");
const isOrganizer = vComputed(() => props.variant === "organizer");
const getVariantClassBasedOnVariant = vComputed(() => {
  if (isOrganizer.value) {
    return "xsmall";
  } else {
    return props.variant === "compact" ? "xlarge" : "xxlarge";
  }
});
</script>
