<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 21V31C44 38 40 41 34 41H14C8 41 4 38 4 31V17C4 10 8 7 14 7H28"
      stroke="#0DC761"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 18L20.26 23C22.32 24.64 25.7 24.64 27.76 23L30.12 21.12"
      stroke="#0DC761"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39 16C41.7614 16 44 13.7614 44 11C44 8.23858 41.7614 6 39 6C36.2386 6 34 8.23858 34 11C34 13.7614 36.2386 16 39 16Z"
      stroke="#FFA400"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
