interface Variant {
  [key: string]: string;
}
interface TextVariant {
  [key: string]: { [key: string]: string };
}

export const variants = [
  "orange",
  "leaf",
  "yellow",
  "green",
  "violet",
  "mango",
  "pink",
  "skyblue",
  "red",
];

export const gradients = {
  leaf: "linear-gradient(90deg, #F56363 -0.13%, #D388FD 49.36%, #88BEB2 99.89%)",
  "leaf-vertical":
    "linear-gradient(180deg, #F56363 -0.13%, #D388FD 49.36%, #88BEB2 99.89%)",
  orange:
    "linear-gradient(149.49deg, #FFA400 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  yellow:
    "linear-gradient(149.49deg, #FFFA1E 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  green:
    "linear-gradient(149.49deg, rgba(4, 199, 96, 0.51) 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  violet:
    "linear-gradient(149.49deg, rgba(204, 141, 255, 0.51) 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  mango:
    "linear-gradient(90deg, #FFFA1E -0.13%, #FFA400 49.36%, #F346F7 99.89%)",
  pink: "linear-gradient(149.49deg, #F346F7 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  skyblue:
    "linear-gradient(149.49deg, #A8BEF0 14.6%, rgba(255, 255, 255, 0) 102.35%)",
  red: "linear-gradient(149.49deg, rgba(240, 96, 96, 0.51) 14.6%, rgba(255, 255, 255, 0) 102.35%)",
} as Variant;

export const textGradients = {
  leaf: {
    start: "#F56363",
    middle: "#D388FD",
    end: "#88BEB2",
  },
  mango: {
    start: "#FFFA1E",
    middle: "#FFA400",
    end: "#F346F7",
  },
  orange: {
    start: "#FFA400",
    end: "#FFFFFF00",
  },
  yellow: {
    start: "#FFFA1E",
    end: "#FFFFFF",
  },
  green: {
    start: "#04C76082",
    end: "#FFFFFF",
  },
  violet: {
    start: "#CC8DFF82",
    end: "#FFFFFF",
  },
  pink: {
    start: "#F346F7",
    end: "#FFFFFF",
  },
  skyblue: {
    start: "#A8BEF0",
    end: "#FFFFFF",
  },
  red: {
    start: "#F0606082",
    end: "#FFFFFF",
  },
} as TextVariant;
