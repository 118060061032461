<template>
  <div :class="baseClass">
    <ClientOnly>
      <Component
        :is="isDisabled"
        v-bind="getButtonProps($props)"
        class="text-body group relative flex w-fit border-2 font-semibold uppercase tracking-wider"
        :disabled="disabled"
        :class="[
          selectButtonClasses,
          compact && 'px-4 py-3.5',
          !compact && !padding && 'px-5 py-4',
          shouldAuthenticate && 'cursor-pointer',
          padding,
          shouldRenderModal && 'modal-open !cursor-default',
          $attrs.class,
        ]"
        :style="customStyle"
        @click="flagTracking(props.text)"
      >
        <div
          v-if="!isCookieAccepted && shouldAuthenticate"
          class="modal-hidden-click absolute left-0 top-0 h-full w-full"
          @click="openModal"
        ></div>
        <div class="flex items-center gap-2">
          <template v-if="reverse">
            <MaterialIcon
              v-if="icon || file?.url"
              id="reverse"
              :name="icon || 'download'"
              :size="iconSize"
              class="mt-1"
            />
            <span
              :class="[
                'mt-1 whitespace-nowrap leading-none',
                underline && 'underline underline-offset-4',
              ]"
              >{{ text }}</span
            >
          </template>
          <template v-else>
            <span
              :class="[
                'mt-1 whitespace-nowrap leading-none',
                underline && 'underline underline-offset-4',
              ]"
              >{{ text }}</span
            >
            <MaterialIcon
              v-if="icon || file?.url"
              :name="icon || 'Download'"
              size="medium"
              :icon-color="iconColor"
            />
          </template>
        </div>
        <ClientOnly v-if="shouldRenderModal">
          <PopUpBanner
            v-if="showDownloadPresentation || !isCookieAccepted"
            :banner-state="bannerType || 'DownloadPresentationBanner'"
            :file="file"
            :file-url="fileUrl"
            :banner-data="popupBanner"
            :override-index="overrideIndex"
            @closed="hideBanner"
          />
        </ClientOnly>
      </Component>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import {
  computed as vComputed,
  PropType,
  ref as vRef,
  onMounted as vOnMounted,
  resolveComponent as vResolveComponent,
} from "vue";
import { useRoute as vUseRoute } from "vue-router";
import * as Cookie from "tiny-cookie";
import { isUrlExternal } from "~/utils/isUrlExternal";
import type { Button } from "~/components/StrapiButton/types";
import { buttonClasses } from "~/components/StrapiButton/const";
import type { IconName } from "~/components/Icon/types";
import MaterialIcon from "~/components/Icon/MaterialIcon.vue";
import { Actions } from "~/types/gtag";
import { trackEvent } from "~/utils/gtag";
import type { Banner as BannerType } from "~/components/Banner/types";
import PopUpBanner from "~/components/Banner/PopUpBanner.vue";

const baseClass = "gt-button";

const props = defineProps({
  url: { type: String as PropType<Button["url"]>, default: undefined },
  text: { type: String as PropType<Button["text"]>, default: undefined },
  newTab: { type: Boolean, default: undefined },
  variant: { type: String as PropType<Button["variant"]>, default: "primary" },
  bannerType: {
    type: String as PropType<BannerType["bannerType"]>,
    default: "DownloadPresentationBanner",
  },
  icon: { type: String as PropType<IconName>, default: undefined },
  underline: { type: Boolean, default: false },
  compact: { type: Boolean, default: false },
  reverse: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  customStyle: { type: String, default: undefined },
  padding: { type: String, default: undefined },
  iconColor: { type: String, default: undefined },
  file: { type: Object as PropType<Button["file"]>, default: undefined },
  iconSize: { type: String, default: "medium" },
  shouldAuthenticate: { type: Boolean, default: false },
  fileUrl: { type: String, default: undefined },
  overrideIndex: { type: Boolean, default: false },
});
const route = vUseRoute();
const strapiUrl = useStrapiUrl();
const baseUrl = strapiUrl.replace("/api", "");
const popupBanner = vRef<BannerType[]>();
const { findOne } = useStrapi4();
const showDownloadPresentation = vRef<boolean>(false);
const isCookieAccepted = vRef<boolean>(false);
const shouldAuthenticate = vComputed(() => props.shouldAuthenticate);
const shouldRenderModal = ref(false);
const isSubscribed = ref(0);

// eslint-disable-next-line require-await
vOnMounted(async () => {
  if (shouldAuthenticate.value) {
    isCookieAccepted.value = Cookie.get("user_subscribed") as unknown as true;

    if (isCookieAccepted.value) {
      isSubscribed.value += 1;
    }
  }
});

if (shouldAuthenticate.value) {
  const { data: banners } = await useAsyncData("banner-download", () =>
    findOne<BannerType[]>("banners", {
      populate: "*",
      filters: {
        type: [
          "DownloadPresentationBanner",
          "DownloadSuccessfulBanner",
          "FreeChapterBanner",
        ],
      },
    })
  );
  popupBanner.value = banners.value.data;
}
function hideBanner() {
  shouldRenderModal.value = false;
  showDownloadPresentation.value = false;

  if (Cookie.get("user_subscribed")) {
    setTimeout(() => {
      isSubscribed.value += 1;
    }, 500);
    isCookieAccepted.value = true;
  }
}

function openModal() {
  if (shouldAuthenticate.value && !showDownloadPresentation.value) {
    showDownloadPresentation.value = true;
    shouldRenderModal.value = true;
  }
}

const isAnchor = vComputed(
  () => (props.url && isUrlExternal(props.url)) || props.file || props.fileUrl
);
const isNuxtLink = vComputed(() => props.url && !isUrlExternal(props.url));

const component = vComputed(() =>
  props.url || props.file || props.fileUrl
    ? isNuxtLink.value
      ? vResolveComponent("CustomLink")
      : isAnchor.value
      ? "a"
      : "div"
    : "button"
);

const isDisabled = vComputed(() =>
  props.disabled ? "button" : component.value
);
const selectButtonClasses = vComputed(() => buttonClasses[props.variant]);

const flagTracking = (title?: string) => {
  title?.toLowerCase() !== "load more" &&
    trackEvent({
      name: Actions.CTA,
      campaignAttributes: false,
      video: undefined,
      contentTypeData: undefined,
      labelText: title,
    });
};

function getButtonProps(btn: Pick<Button, "file" | "url" | "newTab">) {
  if (btn.url && !shouldAuthenticate.value) {
    if (isUrlExternal(btn.url)) {
      return {
        href: btn.url,
        target: "_blank",
        rel: "noopener",
      };
    }

    // NuxtLink
    return {
      to: btn.url,
      target: btn.newTab ? "_blank" : "_self",
    };
  }

  if (btn.file || props.fileUrl) {
    if (shouldAuthenticate.value && !isCookieAccepted.value) {
      return;
    }

    if (isSubscribed.value === 0) {
      return;
    }
    // Anchor
    return {
      href: btn.file?.data?.attributes?.url
        ? `${baseUrl}${btn.file?.data?.attributes?.url}`
        : `${baseUrl}${props.fileUrl}`,
      download: btn.file?.data?.attributes?.name || "test",
      target: "_blank",
    };
  }
}
</script>

<style>
.modal-open .modal-hidden-click {
  display: none;
}
</style>
