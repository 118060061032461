<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 653 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-3"
      d="m188.68,61.27C84.47,61.27,0,145.74,0,249.95s84.47,188.68,188.68,188.68h188.68v-188.68c0-104.2-84.47-188.68-188.68-188.68Z"
    />
    <path
      class="cls-1"
      d="m311.32,61.27c-104.2,0-188.68,84.47-188.68,188.68s84.47,188.68,188.68,188.68h188.68v-188.68c0-104.2-84.47-188.68-188.68-188.68Z"
    />
    <path
      class="cls-2"
      d="m377.36,250.05c0-82.74-53.26-153.03-127.36-178.48-74.1,25.45-127.36,95.74-127.36,178.48,0,104.2,84.47,188.68,188.68,188.68h66.04v-188.68Z"
    />
  </svg>
</template>

<style>
.cls-1 {
  fill: #f346f7;
}

.cls-2 {
  fill: #ffa400;
}

.cls-3 {
  fill: #fffa1e;
}
</style>
