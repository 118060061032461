import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import StrapiButton from "../StrapiButton.vue";

test("snapshot UI testing", () => {
  const wrapper = mount(StrapiButton);
  expect(wrapper.text()).toMatchSnapshot();
});

test("Strapi Button renders correctly with default props", async () => {
  expect(StrapiButton).toBeTruthy(StrapiButton);

  const wrapper = mount(StrapiButton);

  expect(wrapper.props()).toEqual({
    variant: "primary",
    text: undefined,
    url: undefined,
    compact: false,
    underline: false,
    icon: undefined,
    reverse: false,
    disabled: false,
    iconColor: "white",
  });

  await wrapper.setProps({ text: "Primary Button" });
  expect(wrapper.html()).toContain("Primary Button");

  await wrapper.setProps({ url: "https://gentwo.com" });
  expect(wrapper.html()).toContain("https://gentwo.com");
});

test("Strapi Button renders correctly icon", () => {
  const wrapper = mount(StrapiButton, {
    props: { icon: "icon", reverse: true },
  });

  expect(wrapper.findComponent('[data-comp]="icon"').exists()).toBe(true);
});

test("Disabled Strapi Button renders correctly", () => {
  const wrapper = mount(StrapiButton, {
    props: { disabled: true },
  });

  expect(wrapper.attributes("disabled")).toBe("");
  expect(wrapper.classes()).toContain("disabled:cursor-not-allowed");
});
