export const isStoryBook = () => {
  if (
    typeof window !== "undefined" &&
    window.location.href.includes("iframe.html")
  ) {
    return true;
  } else {
    return false;
  }
};
