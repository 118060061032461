import { expect, test } from "vitest";
import { mount } from "@vue/test-utils";
import Headline from "../Headline.vue";

test("snapshot UI testing", () => {
  const wrapper = mount(Headline);
  expect(wrapper.text()).toMatchSnapshot();
});

test("Headline renders correctly with default props", async () => {
  expect(Headline).toBeTruthy(Headline);

  const wrapper = mount(Headline);

  expect(wrapper.props()).toEqual({
    level: "h3",
    variant: "normal",
    text: "The quick brown fox jumps over the lazy dog",
    headlineClass: "",
  });

  await wrapper.setProps({ text: "Text Changed" });
  expect(wrapper.html()).toContain("Text Changed");
});

test("Headline renders correctly html tag", () => {
  expect(Headline).toBeTruthy(Headline);

  const wrapper = mount(Headline);

  expect(wrapper.find("h3").exists()).toBe(true);
});
