<template>
  <NuxtImg
    v-if="!renderDefaultImgTag"
    :src="imgSrc"
    :class="[props.imgClass, !props.objectPosition && 'object-cover']"
    :preload="props.preload"
    :loading="props.loading"
    :decoding="props.decoding"
    :fetchpriority="props.fetchpriority"
    :style="{
      objectPosition: props.objectPosition,
      aspectRatio: props.aspectRatio,
      width: props.width,
      height: props.height,
    }"
  />
  <img
    v-else
    id="storybook-img"
    :src="props.url"
    :class="[props.imgClass, !props.objectPosition && 'object-cover']"
    :preload="props.preload"
    :loading="props.loading"
    :style="{
      objectPosition: props.objectPosition,
      aspectRatio: props.aspectRatio,
      width: props.width,
      height: props.height,
    }"
  />
</template>

<script setup lang="ts">
import {
  PropType,
  ref as vRef,
  onMounted as vOnMounted,
  computed as vComputed,
} from "vue";
import { ImageFormat } from "./types";
import { isStoryBook } from "~/utils/IsStorybook";

const props = defineProps({
  url: {
    type: String,
    default: undefined,
  },
  width: {
    type: [String, Number],
    default: undefined,
  },
  height: {
    type: [String, Number],
    default: undefined,
  },
  preload: { type: Boolean, default: false },
  loading: { type: String as PropType<"eager" | "lazy">, default: "lazy" },
  imgClass: { type: String, default: undefined },
  aspectRatio: {
    type: [String, Number],
    default: undefined,
  },
  objectPosition: {
    type: String,
    default: undefined,
  },
  ext: {
    type: String,
    default: "",
  },
  formats: {
    type: Object as PropType<ImageFormat>,
    default: () => ({}),
  },
  preferedSize: {
    type: String as PropType<"small" | "medium" | "large" | "thumbnail">,
    default: undefined,
  },
  name: {
    type: String,
    default: "",
  },
  caption: {
    type: String,
    default: "",
  },
  provider: {
    type: String,
    default: "",
  },
  decoding: {
    type: String as PropType<"async" | "auto" | "sync">,
    default: "async",
  },
  fetchpriority: {
    type: String as PropType<"high" | "low">,
    default: undefined,
  },
});

const renderDefaultImgTag = vRef(false);

vOnMounted(() => {
  renderDefaultImgTag.value = isStoryBook();
});

const strapiUrl = isStoryBook() ? "" : useStrapiUrl();
const baseUrl = strapiUrl.split("/api")[0];
const isSvg = vComputed(() => props.ext === ".svg");

const urlForPreferredSize = vComputed(
  () => props.preferedSize && props.formats?.[props.preferedSize]?.url
);
const imagePath = vComputed(() => {
  if (urlForPreferredSize.value) {
    return urlForPreferredSize.value;
  }
  return props.url;
});

const removeBlurredImages = () => {
  if (typeof document !== "undefined") {
    const allImages = document.querySelectorAll("img");
    if (allImages) {
      allImages.forEach((image) => {
        props.loading === "lazy" &&
          !image.className.includes("loaded") &&
          image.classList.add("blurred-img");
        if (image.complete) {
          image.classList.add("loaded");
          removeBlur(image);
        } else {
          image.addEventListener("load", () => {
            image.classList.add("loaded");
            removeBlur(image);
          });
        }
      });
    }
  }
};

vOnMounted(() => {
  removeBlurredImages();
});

function removeBlur(image: HTMLImageElement) {
  image.classList.remove("blurred-img");
}

const imgSrc = vComputed(() =>
  props.url?.includes("https") || props.url?.includes("./")
    ? imagePath.value
    : `${baseUrl}${imagePath.value}`
);
</script>
<style lang="scss">
.blurred-img {
  opacity: 0;
  animation: pulse 1.5s infinite;
  background-color: white;
  transition: 200ms ease-in-out;
  filter: blur(15px);
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}
</style>
