<template>
  <svg
    width="528"
    height="422"
    viewBox="0 0 528 422"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M105.164 421.297V210.888C105.164 210.688 105.164 210.487 105.164 210.297C46.8407 210.297 -0.436401 257.53 -0.436401 315.797C-0.436401 374.065 46.8407 421.297 105.164 421.297Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M210.764 315.797V421.297H105.164V421.888H316.364C433.009 421.888 527.564 327.423 527.564 210.888C527.564 94.3528 433.009 -0.111938 316.364 -0.111938C199.718 -0.111938 105.491 94.0363 105.174 210.297C163.497 210.297 210.764 257.53 210.764 315.797Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M105.164 421.297H210.764V315.797C210.764 257.53 163.486 210.297 105.174 210.297C105.174 210.498 105.174 210.698 105.174 210.888V421.297H105.164Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
