import { expect, test } from "vitest";
import { mount, shallowMount } from "@vue/test-utils";
import Header from "~/components/Header/Header.vue";
import { menuItems } from "~/components/Header/const";

test("Header Snapshot UI testing", () => {
  const wrapper = mount(Header, {});
  expect(wrapper.text()).toMatchSnapshot();
});

test("Header links and svgs work correctly", () => {
  expect(Header).toBeTruthy();

  const mappedItems = menuItems;
  const wrapper = shallowMount(Header, {
    items: menuItems,
    global: {
      components: {
        NuxtLink: {
          template: "<a><slot/></a>",
        },
      },
    },
  });

  mappedItems[0] = {
    ...menuItems[0],
    items: menuItems[0].items?.map((el, i) =>
      i === 0
        ? {
            ...el,
            subItems: el.subItems?.map((sub, index) =>
              index === 0 ? { ...sub, newTab: true } : sub
            ),
          }
        : el
    ),
  };

  const links = wrapper.findAllComponents(".sub-item");

  links.forEach((link) => {
    expect(link.attributes("target")).toBe("_self");
  });

  mappedItems[0] = { ...menuItems[0], cta: undefined };

  wrapper
    .findAllComponents("svg")
    .forEach((svg) => expect(svg.exists()).toBe(true));
});

test("Header toggle buttons work correctly", () => {
  expect(Header).toBeTruthy();
  const wrapper = mount(Header, { menuItems });

  const buttons = wrapper.findAll("button");
  buttons.forEach(async (button) => await button.trigger("click"));

  const list = wrapper.findAll("li");
  list.forEach(async (li) => await li.trigger("click"));
});
