<template>
  <svg
    width="301"
    height="241"
    viewBox="0 0 301 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M240.626 240.355V120.84C240.626 120.726 240.626 120.612 240.626 120.504C273.754 120.504 300.608 147.333 300.608 180.429C300.608 213.526 273.754 240.355 240.626 240.355Z"
        fill="#F346F7"
      />
    </g>
    <g>
      <path
        d="M180.644 180.429V240.355H240.626V240.69H120.662C54.4056 240.69 0.697588 187.033 0.697588 120.84C0.697588 54.6461 54.4056 0.989014 120.662 0.989014C186.918 0.989014 240.44 54.4663 240.62 120.504C207.492 120.504 180.644 147.333 180.644 180.429Z"
        fill="#FFFA1E"
      />
    </g>
    <g>
      <path
        d="M240.626 240.355H180.644V180.429C180.644 147.333 207.498 120.504 240.62 120.504C240.62 120.618 240.62 120.732 240.62 120.84V240.355H240.626Z"
        fill="#FFA400"
      />
    </g>
  </svg>
</template>
