import { useGtm } from "@gtm-support/vue-gtm";
import * as Cookie from "tiny-cookie";
import { Actions, EventData, EventParams } from "~/types/gtag";
import { Video } from "~/components/VideoTestimonials/types";
import { postAttributes } from "~/types";

const GA_COOKIE_NAME = "_ga";
const ATT_COOKIE_NAME = "_att";

const whitelistContentType = [
  "Alternative Assets",
  "Traditional Assets",
  "Digital Assets",
];
interface Tag {
  attributes: {
    administratorTitle: string;
  };
}

/**
 * Pushes a Google Tag Manager event based on the provided event parameters.
 *
 * @param {EventParams} data - An object containing the event parameters.
 */
export function trackEvent(data: EventParams): void {
  // Get the GTM instance
  const gtm = useGtm();
  let dataLayer: EventData = {};
  // Retrieve Google Analytics client ID from the cookie
  const getGAclientid = Cookie.get(GA_COOKIE_NAME);
  // Include additional tracking attributes if the flag is set
  if (data.campaignAttributes && Cookie.get(ATT_COOKIE_NAME)) {
    // Parse the _att cookie and extract relevant keys
    const _attObjCookie = JSON.parse(Cookie.get(ATT_COOKIE_NAME)!);
    const _attObjKeys = ["utm_campaign", "utm_source", "utm_source_detail"];

    for (const key of _attObjKeys) {
      const value = _attObjCookie[key];
      if (value) {
        // Replace double quotes and add the key-value pair to the data object
        dataLayer = { ...dataLayer, [key]: value.replace(/"/g, "") };
      }
    }
  }

  // Switch statement to handle different event types
  switch (data.name) {
    case Actions.SIGN_UP:
      dataLayer = {
        ...dataLayer,
        event: Actions.SIGN_UP,
        journey_status: "Registered",
        ui_type: "Form",
        ui_name: "Contact-us form",
        action_type: "Form submission",
        gaclientID: getGAclientid!,
      };
      break;
    case Actions.CONTACT_US:
      dataLayer = {
        ...dataLayer,
        event: Actions.CONTACT_US,
        journey_status: "Registered",
        ui_type: "Form",
        ui_name: "Contact-us form",
        action_type: "Form submission",
        gaclientID: getGAclientid!,
      };
      break;
    case Actions.CTA:
      dataLayer = {
        ...dataLayer,
        event: Actions.CTA,
        ui_type: "Call-To-Action",
        action_type: "Click",
        ui_name: data.labelText,
      };
      break;
    case Actions.WATCH_VIDEO:
      if (findMatchingTag(data.video)) {
        dataLayer = {
          ...dataLayer,
          content_type: findMatchingTag(data.video),
        };
      }
      dataLayer = {
        ...dataLayer,
        event: Actions.WATCH_VIDEO,
        ui_name: data.video?.title,
        action_type: "Watch video",
      };
      break;
    case Actions.PAGE_DETAILS:
      dataLayer = {
        event: Actions.PAGE_DETAILS,
        page: data.path,
      };
      if (findMatchingTag(data.contentTypeData)) {
        dataLayer = {
          ...dataLayer,
          content_type: findMatchingTag(data.contentTypeData),
        };
      }
      break;
    default:
      return;
  }

  // Push the event data to Google Tag Manager
  if (Object.keys(dataLayer).length > 0) {
    gtm?.trackEvent(dataLayer);
  }
}

function findMatchingTag(
  target: Video | postAttributes | undefined
): string | undefined {
  if (target?.tags?.data?.length) {
    for (const priority of whitelistContentType) {
      const matchingTag = (target.tags.data as Tag[]).find(
        (tag: Tag) => tag.attributes.administratorTitle === priority
      );
      if (matchingTag) {
        return matchingTag.attributes.administratorTitle;
      }
    }
  }

  return undefined;
}
